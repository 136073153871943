import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';

class ThAuthJoinDetailClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberInfo: null,
            codeRegister: null,
            password1: '',
            password2: '',
            prefix: '',
            firstname: '',
            lastname: '',
            firstnameth: '',
            lastnameth: '',
            mobile: '',
            bdDay: '',
            bdMonth: '',
            agree1: false,
            agree2: false,
            registerMsg: '',
            registerMsgPwd: '',
            registerMsgPwd2: '',
            registerMsgFname: '',
            registerMsgLname: '',
            registerMsgAgree: '',
            registerMsgMobile: '',
            submitting: false, // Add a state variable to track form submission status
            showPasswordTips: false,
            renderCheck: false,
            cssPopupBD: 'popuphide'
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const searchParams = new URLSearchParams(this.props.location.search);
        const codeRegister = searchParams.get('codereg');
        if (!codeRegister) {
            const dataToSend = { reason: "No code!!" };
            this.props.history.replace('/joinnowerr', dataToSend);
            return;
        }

        // Update state and call API to get member information
        this.setState({ codeRegister }, () => {
            this.callApiRegisterInfo(codeRegister);
        });

        {
            // Google analytic
            const documentPath = "TH Join - detail"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    async callApiRegisterInfo(codeRegister) {
        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { codereg: codeRegister, RefLang: "th", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmRegisterStep02`, requestOptions);
            const data = await response.json();

            if (data) {
                document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

                if (data.responseCode == "OK") {
                    this.setState({ memberInfo: data, renderCheck: true }, () => {
                        // Initialize this java after loaded
                        window.$(".toggle-password").click(function () {
                            window.$(this).toggleClass("fa-eye fa-eye-slash");
                            var input = window.$(window.$(this).attr("toggle"));
                            if (input.attr("type") === "password") {
                                input.attr("type", "text");
                            } else {
                                input.attr("type", "password");
                            }
                        });
                    });
                }
                else {
                    const dataToSend = { reason: data.responseMessage };
                    this.props.history.replace('/thjoinnowerr', dataToSend);
                }
            }
            else {
                const dataToSend = { reason: "No data!!" };
                this.props.history.replace('/thjoinnowerr', dataToSend);
            }
        } catch (error) {
            console.error('API call error :', error);
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        } finally {
            
        }
    }

    handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox') {
            this.setState({ [name]: checked });
        } else {
            let errors = '';
            if (name === "password1") {
                errors = this.validatePassword(value);
            }

            this.setState({ [name]: value, registerMsgPwd: errors });
        }

        if (name === "bdMonth") {
            this.setState({
                bdDay: ''
            });
        }
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { memberInfo, password1, password2, prefix, firstname, lastname, firstnameth, lastnameth, mobile, bdDay, bdMonth, agree1, codeRegister } = this.state;
        {
            let canSubmit = true;

            //check form condition
            if (password1 === "") {
                this.setState({ registerMsgPwd2: "กรุณากรอกรหัสผ่าน" });
                canSubmit = false;
            }

            if (password2 === "") {
                this.setState({ registerMsgPwd2: "กรุณากรอกยืนยันรหัสผ่าน" });
                canSubmit = false;
            }

            if (password1 !== password2) {
                this.setState({ registerMsgPwd2: "รหัสผ่านที่คุณกรอกไม่ตรงกัน" });
                canSubmit = false;
            }

            const errors = this.validatePassword(password1);
            if (errors) {
                this.setState({ registerMsgPwd: errors });
                canSubmit = false;
            }

            if (firstname === "") {
                this.setState({ registerMsgFname: "กรุณากรอกชื่อ (อังกฤษ)" });
                canSubmit = false;
            }

            if (lastname === "") {
                this.setState({ registerMsgLname: "กรุณากรอกนามสกุล (อังกฤษ)" });
                canSubmit = false;
            }

            // Mobile validation
            if (mobile && (!/^0\d{9}$/.test(mobile))) {
                this.setState({ registerMsgMobile: "กรุณากรอกเบอร์โทรศัพท์ ที่ถูกต้อง)" });
                canSubmit = false;
            }

            //if (agree1 !== true) {
            //    this.setState({ registerMsgAgree: "กรุณายอมรับ ความประสงค์ในการรับข้อมูลข่าวสาร" });
            //    canSubmit = false;
            //}

            if (canSubmit == false) {
                return;
            }

            //No BirthdayD show popup
            if (bdDay === "") {
                this.setState({ cssPopupBD: "popup" });
                return;
            }
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { Email: memberInfo.responseMessage, CurrentPassword: password1, RefLang: 'th', Salutation: prefix, FirstName: firstname, LastName: lastname, FirstNameTH: firstnameth, LastNameTH: lastnameth, MobilePhone: mobile, BirthdayD: bdDay, BirthdayM: bdMonth, Agree1: agree1, CodeReg: codeRegister, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmRegisterStep03`, requestOptions);
            const data = await response.json();
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

            if (data.responseCode === "OK") {
                const dataToSend = { responseData: data };
                this.props.history.replace('/thjoinnowthx', dataToSend);
            }
            else {                                
                const dataToSend = { responseData: data };
                this.props.history.replace('/thjoinnowerr', dataToSend);                
            }
        } catch (error) {
            console.error('Error during api:', error);
            // Handle error appropriately, e.g., show an error message to the user

            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        } finally {
            
        }
    };

    handleSubmit2 = async e => {
        e.preventDefault();
        const { memberInfo, password1, password2, prefix, firstname, lastname, firstnameth, lastnameth, mobile, bdDay, bdMonth, agree1, codeRegister } = this.state;
        {
            let canSubmit = true;

            //check form condition
            if (password1 === "") {
                this.setState({ registerMsgPwd2: "กรุณากรอกรหัสผ่าน" });
                canSubmit = false;
            }

            if (password2 === "") {
                this.setState({ registerMsgPwd2: "กรุณากรอกยืนยันรหัสผ่าน" });
                canSubmit = false;
            }

            if (password1 !== password2) {
                this.setState({ registerMsgPwd2: "รหัสผ่านที่คุณกรอกไม่ตรงกัน" });
                canSubmit = false;
            }

            const errors = this.validatePassword(password1);
            if (errors) {
                this.setState({ registerMsgPwd: errors });
                canSubmit = false;
            }

            if (firstname === "") {
                this.setState({ registerMsgFname: "กรุณากรอกชื่อ (อังกฤษ)" });
                canSubmit = false;
            }

            if (lastname === "") {
                this.setState({ registerMsgLname: "กรุณากรอกนามสกุล (อังกฤษ)" });
                canSubmit = false;
            }

            // Mobile validation
            if (mobile && (!/^0\d{9}$/.test(mobile))) {
                this.setState({ registerMsgMobile: "กรุณากรอกเบอร์โทรศัพท์ ที่ถูกต้อง)" });
                canSubmit = false;
            }

            //if (agree1 !== true) {
            //    this.setState({ registerMsgAgree: "กรุณายอมรับ ความประสงค์ในการรับข้อมูลข่าวสาร" });
            //    canSubmit = false;
            //}

            if (canSubmit == false) {
                return;
            }
        }

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        // Prepare and encrypt the payload
        const payload = { Email: memberInfo.responseMessage, CurrentPassword: password1, RefLang: 'th', Salutation: prefix, FirstName: firstname, LastName: lastname, FirstNameTH: firstnameth, LastNameTH: lastnameth, MobilePhone: mobile, BirthdayD: bdDay, BirthdayM: bdMonth, Agree1: agree1, CodeReg: codeRegister, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        // Prevent double-clicking by disabling the submit button and changing cursor to 'wait'
        this.setState({ submitting: true });

        // Add the wait cursor class to the body element
        document.body.classList.add('wait-cursor');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmRegisterStep03`, requestOptions);
            const data = await response.json();
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class

            if (data.responseCode === "OK") {
                const dataToSend = { responseData: data };
                this.props.history.replace('/thjoinnowthx', dataToSend);
            }
            else {
                const dataToSend = { responseData: data };
                this.props.history.replace('/thjoinnowerr', dataToSend);
            }
        } catch (error) {
            console.error('Error during api:', error);
            // Handle error appropriately, e.g., show an error message to the user

            // Enable the submit button and change cursor back to 'auto' after form submission completes
            this.setState({ submitting: false });
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        } finally {

        }
    };

    cancelSubmit = () => {
        this.setState({
            cssPopupBD: 'popuphide'
        });
    };

    validatePassword = (password) => {
        const minLength = 9;
        const maxLength = 15;
        const numberRegex = /[0-9]/;
        const capitalLetterRegex = /[A-Z]/;
        const lowerCaseLetterRegex = /[a-z]/;
        const specialCharRegex = /[!@#$*;&]/;

        if (password.length === 0) {
            return 'กรุณากรอกรหัสผ่านใหม่';
        }
        if (password.length < minLength || password.length > maxLength) {
            return `ต้องมีความยาวรหัสตั้งแต่ ${minLength} ถึง ${maxLength} ตัวอักษร`;
        }
        if (!numberRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวเลขอย่างน้อยหนึ่งตัว';
        }
        if (!capitalLetterRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่อย่างน้อยหนึ่งตัวอักษร';
        }
        if (!lowerCaseLetterRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวอักษรพิมพ์เล็กอย่างน้อยหนึ่งตัวอักษร';
        }
        if (!specialCharRegex.test(password)) {
            return 'ต้องประกอบด้วยตัวอักษรพิเศษอย่างน้อยหนึ่งตัวอักษร เช่น (e.g., !,@,#,$,*,;,&). ';
        }

        return ''; // Return an empty string if all checks pass
    };

    handleFocus = () => {
        this.setState({ showPasswordTips: true });
    };

    handleBlur = () => {
        this.setState({ showPasswordTips: false });
    };

    render() {
        const { memberInfo, password1, password2, prefix, firstname, lastname, firstnameth, lastnameth, mobile, bdDay, bdMonth, agree1, agree2, registerMsg, registerMsgPwd, registerMsgPwd2, registerMsgFname, registerMsgLname, submitting, renderCheck, showPasswordTips, registerMsgAgree, cssPopupBD, registerMsgMobile } = this.state;

        const getDaysInMonth = (month) => {
            const daysInMonth = {
                '01': 31, // January
                '02': 29, // February
                '03': 31, // March
                '04': 30, // April
                '05': 31, // May
                '06': 30, // June
                '07': 31, // July
                '08': 31, // August
                '09': 30, // September
                '10': 31, // October
                '11': 30, // November
                '12': 31, // December
            };

            return daysInMonth[month] || 31; // Default to 31 if month is not selected
        };

        const days = Array.from({ length: getDaysInMonth(bdMonth) }, (_, i) => i + 1);
        return (
            <>
                <ThNavHeader />
                {memberInfo && (
                    <main id="main">
                        <section className="top-sr-bg bg-gray1">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col col-md-6">
                                        <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="text-black">สตาร์บัคส์ รีวอร์ด</span></h1>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {renderCheck ? (
                            <>
                                <section className="section-create-account overflow-hidden pb-5">
                                    <div className="container p-3 p-md-0">
                                        <div className="row">
                                            <div className="col-md-12 mb-5 pt-3 text-start">
                                                <h2 className="h3 mt-5 mb-4 fw-bold text-dark-212121">ลงทะเบียน</h2>
                                                <form className="form-sr" onSubmit={this.handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-4">
                                                            <label className="form-label d-none">Username.</label>
                                                            <h5 className="fw-semibold text-green-1">{memberInfo.responseMessage}</h5>
                                                            <div className="form-text">ชื่อบัญชีสมาชิกของคุณ</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">รหัสผ่าน</label>
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    id="password-field"
                                                                    type="password"
                                                                    className="form-control border-end-0"
                                                                    placeholder="*รหัสผ่าน" name="password1"
                                                                    value={password1}
                                                                    onChange={this.handleChange}
                                                                    onFocus={this.handleFocus}
                                                                    onBlur={this.handleBlur}
                                                                />
                                                                <span className="input-group-text"><i toggle="input[name='password1']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                                <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd}</div>
                                                                {showPasswordTips && (
                                                                    <div id="new-password-field-tips" className="passwordtips-box">
                                                                        <h4 className="h6 text-decoration-underline">Password Tips</h4>
                                                                        <p className="form-text">
                                                                            ความยาวรหัสตั้งแต่ 9 ถึง 15 ตัวอักษร<br />
                                                                            ประกอบด้วยตัวเลขอย่างน้อยหนึ่งตัว<br />
                                                                            ประกอบด้วยตัวอักษรพิมพ์ใหญ่อย่างน้อยหนึ่งตัวอักษร<br />
                                                                            ประกอบด้วยตัวอักษรพิมพ์เล็กอย่างน้อยหนึ่งตัวอักษร<br />
                                                                            ประกอบด้วยตัวอักษรพิเศษอย่างน้อยหนึ่งตัวอักษร เช่น !,@,#,$,*,;,&
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">ยืนยันรหัสผ่าน</label>
                                                            <div className="input-group mb-3">
                                                                <input id="password-confirm-field" type="password" className="form-control border-end-0" placeholder="*ยืนยันรหัสผ่าน" name="password2" value={password2} onChange={this.handleChange} />
                                                                <span className="input-group-text"><i toggle="input[name='password2']" className="fa fa-fw fa-eye-slash toggle-password" /></span>
                                                                <div className="invalid-feedback" style={{ display: 'block' }}>{registerMsgPwd2}</div>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                    <h4 className="h4 mt-4 mb-3"><strong>ข้อมูลส่วนบุคคล</strong></h4>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">คำนำหน้า (ไม่จำเป็นต้องระบุ)</label>
                                                            <select className="form-select" name="prefix" value={prefix} onChange={this.handleChange}>
                                                                <option value="">กรุณาเลือก</option>
                                                                <option value="Mr.">นาย</option>
                                                                <option value="Mrs.">นาง</option>
                                                                <option value="Ms">นางสาว</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">ชื่อ (ภาษาอังกฤษ)</label>
                                                            <input type="text" className="form-control" placeholder="ชื่อ (ภาษาอังกฤษ)" name="firstname" value={firstname} onChange={this.handleChange} />
                                                            <div className="text-start mt-1 text-alert-2">
                                                                {registerMsgFname}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">นามสกุล (ภาษาอังกฤษ)</label>
                                                            <input type="text" className="form-control" placeholder="นามสกุล (ภาษาอังกฤษ)" name="lastname" value={lastname} onChange={this.handleChange} />
                                                            <div className="text-start mt-1 text-alert-2">
                                                                {registerMsgLname}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">ชื่อ ภาษาไทย (ไม่จำเป็นต้องระบุ)</label>
                                                            <input type="text" className="form-control" placeholder="ชื่อภาษาไทย (ไม่จำเป็นต้องระบุ)" name="firstnameth" value={firstnameth} onChange={this.handleChange} />
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">นามสกุล ภาษาไทย (ไม่จำเป็นต้องระบุ)</label>
                                                            <input type="text" className="form-control" placeholder="นามสกุลภาษาไทย (ไม่จำเป็นต้องระบุ)" name="lastnameth" value={lastnameth} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">เบอร์โทรศัพท์ (ไม่จำเป็นต้องระบุ)</label>
                                                            <input type="number" className="form-control" placeholder="เบอร์โทรศัพท์ (ไม่จำเป็นต้องระบุ)" name="mobile" value={mobile} onChange={this.handleChange} />
                                                            <div className="text-start mt-1 text-alert-2">
                                                                {registerMsgMobile}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <hr className="my-3" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <p className="h6"><strong>วันเกิด (ไม่จำเป็นต้องระบุ)</strong><br />
                                                                <span className="small">เพิ่มความพิเศษในวันเกิดของคุณด้วยของขวัญวันเกิดจากเราเมื่อปรับระดับเป็น โกลด์</span></p>
                                                            <div className="row g-3">
                                                                <div className="col-md-3 mb-3">
                                                                    <select className="form-select" name="bdMonth" value={bdMonth} onChange={this.handleChange}>
                                                                        <option value="">เดือน</option>
                                                                        <option value="01">ม.ค.</option>
                                                                        <option value="02">ก.พ.</option>
                                                                        <option value="03">มี.ค.</option>
                                                                        <option value="04">เม.ย.</option>
                                                                        <option value="05">พ.ค.</option>
                                                                        <option value="06">มิ.ย.</option>
                                                                        <option value="07">ก.ค.</option>
                                                                        <option value="08">ส.ค.</option>
                                                                        <option value="09">ก.ย.</option>
                                                                        <option value="10">ต.ค.</option>
                                                                        <option value="11">พ.ย.</option>
                                                                        <option value="12">ธ.ค.</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3 mb-3">
                                                                    <select className="form-select" name="bdDay" value={bdDay} onChange={this.handleChange}>
                                                                        <option value="">วัน</option>
                                                                        {days.map((day) => (
                                                                            <option key={day} value={day}>
                                                                                {day}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <hr className="mt-2 mb-3" />
                                                        </div>
                                                    </div>
                                                    <div className="row form-check-term">
                                                        <div className="col-md-12 mb-3">
                                                            <p className="h6"><strong>ความประสงค์ในการรับข้อมูลข่าวสาร</strong></p>
                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input" name="agree1" checked={agree1}
                                                                    onChange={this.handleChange} />
                                                                <label className="form-check-label ms-3 d-block"><span className="pb-3" style={{ fontWeight: 600, fontSize: '15px' }}>ข้าพเจ้าประสงค์จะรับข้อมูลข่าวสารทางการตลาดจากสตาร์บัคส์ประเทศไทย</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="font-15px">โดยคลิก "ลงทะเบียน" ถือว่าคุณได้ยอมรับใน เงื่อนไขการใช้งาน และนโยบายความเป็นส่วนตัว <a href="https://www.starbucks.co.th/online-policies/terms-of-use/" target="_blank">เงื่อนไขการใช้งาน</a> และ <a href="https://www.starbucks.co.th/online-policies/privacy-notice/" target="_blank">นโยบายความเป็นส่วนตัว</a></p>
                                                    <div className="text-start mt-4">
                                                        <button type="submit" className="btn btn-primary rounded-pill px-4 text-decoration-none" disabled={submitting}>
                                                            {submitting ? 'ลงทะเบียน...' : 'ลงทะเบียน'}
                                                        </button>

                                                        <div className={cssPopupBD} id="BirthdayModal" tabIndex={-1}>
                                                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h1 className="modal-title fs-5" id="BirthdayModalLabel">คุณจะพลาดสิทธิพิเศษในวันเกิด?</h1>
                                                                        <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" onClick={this.cancelSubmit} />
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <p>เพื่อไม่ให้พลาดรับสิทธิพิเศษในวันเกิดเมื่อคุณได้ปรับระดับเป็น Gold กรุณาใส่วันและเดือนเกิดของคุณ</p>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={this.handleSubmit2}>ได้</button>
                                                                        &nbsp;&nbsp;
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.cancelSubmit}>ไม่ได้</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-start mt-2 text-alert-2">
                                                        {registerMsg}
                                                        {registerMsgAgree}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        ) : (
                            <>
                                {/*Loading*/}
                                <div className="col-md-5">
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src="media/loading-gif.gif" alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </div>
                            </>
                        )}
                    </main>
                )}
                <ThNavFooter />
            </>
        );
    }
}

export const ThAuthJoinDetail = withRouter(ThAuthJoinDetailClass);
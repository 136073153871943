import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';
import { ThSharedComponentBenefit01 } from './ThSharedComponentBenefit01';

import { getClientId } from './utils/analyticsclientIdUtils';

export class ThHome extends Component {
    static displayName = ThHome.name;

    componentDidMount() {
        // Use jQuery to toggle password visibility
        window.$(".accordion-image img:eq(0)").nextAll().hide();
        window.$(".accordion350tab button").click(function (e) {
            var imageId = window.$(this).attr('data-image');
            window.$(".accordion-image #" + imageId).show().siblings().hide();
        });

        {
            // Google analytic
            const documentPath = "THHome"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }    

    render() {
        return (
            <>
                <ThNavHeader />

                <main id="main">
                    <section className="top-sr-bg bg-gray1">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col col-md-6">
                                    <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="text-black">สตาร์บัคส์ รีวอร์ด</span></h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="cover-home overflow-hidden">
                        <div className="p-0 p-md-5 mb-0 mb-md-4 bg-star-home">
                            <div className="container py-0 py-md-5">
                                <h2 className="display-3 fw-bold mt-0 mt-md-5 pt-5 d-none d-md-block"><span className="text-style-head-1">REWARDS TO MAKE</span><br />
                                    <span className="text-style-head-2">YOUR DAY SHINE</span></h2>
                                <h2 className="display-5 fw-bold mt-0 mt-md-5 pt-5 d-block d-md-none"><span className="text-style-head-1">REWARDS TO MAKE</span><br />
                                    <span className="text-style-head-2">YOUR DAY SHINE</span></h2>
                                <p className="less-lead">สมัครสมาชิกเพื่อรับสิทธิประโยชน์มากมาย</p>
                                <Link to="/thauthjoinnow" className="btn btn-primary rounded-pill text-decoration-none mb-3 btn-lg py-1">ลงทะเบียน</Link>
                                <p className="less-lead mb-0 mb-md-5 pb-5">หรือ<a href="https://www.starbuckscardth.in.th/cards/newhome" target="_blank" alt="Join now" rel="noopener noreferrer"> สมัครผ่าน Starbucks&reg; TH app</a></p>
                            </div>
                        </div>
                    </section>

                    <ThSharedComponentBenefit01 />

                    <section className="section-start pb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-0 mb-md-5 pt-3 text-center">
                                    <h2 className="h3 mt-0 mt-md-5 fw-bold text-dark-212121">เพียงขั้นตอนง่ายๆ</h2>
                                    <p className="mt-3 text-dark-212121">ให้คุณรับดาวสะสม และแลกสิทธิประโยชน์ได้มากยิ่งขึ้น</p>
                                </div>
                            </div>
                            <div className="row gx-5 text-dark-000000">
                                <div className="col-sm-12 col-md-4 pb-4 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box2-Getting-started-is-easy-01-600.png")} alt="Create an account" className="img-fluid" style={{ maxWidth: '150px' }} /></div>
                                    <h4 className="fs-5 fw-bold px-3">สมัครสมาชิก</h4>
                                    <p>เริ่มด้วยการ สมัครสมาชิก คุณสามารถสมัครผ่าน Starbucks&reg; TH app เพื่อรับสิทธิประโยชน์ต่างๆ</p>
                                </div>
                                <div className="col-sm-12 col-md-4 pb-4 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box2-Getting-started-is-easy-02-600.png")} alt="Collect Stars based on how much you spend. For every 25B, collect 1 Stars." className="img-fluid" style={{ maxWidth: '150px' }} /></div>
                                    <h4 className="fs-5 fw-bold px-3">สะสมดาวจากการใช้จ่าย <br/>
                                        ทุกๆ 25 บาท รับดาว 1 ดวง</h4>
                                    <p>คุณสามารถนำดาวมาแลกสิทธิประโยชน์มากมาย เช่น แลกรับส่วนลดสำหรับเครื่องดื่ม ขนม และสินค้าอื่นๆ หรือเครื่องดื่มฟรี เริ่มต้นที่การใช้ดาวเพียง 20 ดวง</p>
                                </div>
                                <div className="col-sm-12 col-md-4 pb-0 pb-md-4 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box2-Getting-started-is-easy-03-600.png")} alt="Get the best of Starbucks&reg; Rewards right at your fingertips." className="img-fluid" style={{ maxWidth: '150px' }} /></div>
                                    <h4 className="fs-5 fw-bold px-3">แอปพลิเคชัน <br/>
                                        เฉพาะสมาชิกสตาร์บัคส์ รีวอร์ด</h4>
                                    <p>ไม่ว่าจะสั่งซื้อล่วงหน้าแล้วรับที่ร้าน, สั่งที่โต๊ะ พร้อมเสิร์ฟ โดยบาริสต้า, สั่งขนมเครื่องดื่มที่ชื่นชอบส่งตรงถึงคุณ, หรือติดตามสถานะดาวและสิทธิประโยชน์ที่คุณได้รับ แลกรับสิทธิประโยชน์ ค้นหาร้านค้า และอื่นๆ อีกมากมาย</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-collect2 pb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-0 mb-md-5 pt-3 text-center">
                                    <h2 className="h3 mt-5 fw-bold text-dark-212121">สะสมดาว เพื่อปรับระดับเป็น <span className="text-gold">โกลด์</span></h2>
                                    <p className="mt-3 text-dark-212121">คุณจะได้ปรับระดับเป็น โกลด์ เมื่อสะสมดาวครบ 300 ดวง ภายใน 12 เดือน</p>
                                </div>
                            </div>
                            <div className="row justify-content-center gx-5 text-dark-000000">
                                <div className="col-sm-12 col-md-5 pb-4 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box3-Celebrate-with-a-birthday-treat-big.png")} alt="Celebrate with a birthday treat" className="img-fluid" style={{ maxWidth: '250px' }} /></div>
                                    <h4 className="fs-5 px-3 fw-semibold">เฉลิมฉลองกับช่วงเวลาแสนพิเศษในเดือนเกิด ด้วยเครื่องดื่มสุดโปรด และขนมเค้กแสนอร่อย</h4>
                                </div>
                                <div className="col-sm-12 col-md-5 pb-0 pb-md-5 text-center">
                                    <div className="mb-3"><img src={require("./media/rewards/img-box3-Get-a-Starbucks-Rewards-Gift-big.png")} alt="Get a Starbucks Rewards Gift" className="img-fluid" style={{ maxWidth: '250px' }} /></div>
                                    <h4 className="fs-5 px-3 fw-semibold">รับของขวัญสุดพิเศษจาก <span className="fw-bold">สตาร์บัคส์ รีวอร์ด</span></h4>
                                </div>
                            </div>
                        </div>
                    </section>
                 
                    <section className="section-banner-slide-bottom p-0">
                        <div className="container-fluid p-0 m-0">
                            <div className="row p-0 m-0">
                                <div className="col-6 p-0 m-0">
                                    <Link to="/thpromograbsr">
                                        <img src={require("./media/rewards/Banner_SR_01.webp")} alt="Banner" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="col-6 p-0 m-0">
                                    <Link to="/thpromolineman">
                                        <img src={require("./media/rewards/Banner_SR_02.webp")} alt="Banner" className="img-fluid" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section-faq pb-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-4 mb-md-5 pt-3">
                                    <h2 className="h3 mt-5 fw-bold text-dark-000000">คำถามที่พบบ่อย</h2>
                                    <p className="mt-3 mb-5 text-dark-212121">หากคุณมีข้อสงสัย บาริสต้ายินดีตอบข้อสงสัยของคุณทุกเวลา หรือคลิกเพื่ออ่าน <Link to="/thfaq">คำถามที่พบบ่อย</Link></p>
                                    <h4 className="fs-6 fw-bold text-dark-000000">การสะสมดาว</h4>
                                    <p className="text-dark-212121 mb-4">ใช้จ่ายผ่านบัตรสตาร์บัคส์ การ์ด ที่ลงทะเบียน หรือ Starbucks&reg; TH app เพื่อสะสมดาว การซื้อบัตรสตาร์บัคส์ การ์ดหรือการเติมเงินจะไม่สามารถสะสมดาวได้</p>
                                    <h4 className="fs-6 fw-bold text-dark-000000">การแลกรับสิทธิประโยชน์</h4>
                                    <p className="text-dark-212121 mb-4">คุณสามารถแลกรับสิทธิประโยชน์ได้ที่ร้านสตาร์บัคส์ในประเทศไทย และที่ Starbucks&reg; TH app</p>
                                    <h4 className="fs-6 fw-bold text-dark-000000">ดาวสะสมหมดอายุ</h4>
                                    <p className="text-dark-212121 mb-4">ในโปรแกรม Starbucks&reg; Rewards ดาวสะสมเพื่อรักษาสถานะมีอายุ 12 เดือน นับจากวันที่ลงทะเบียนหรือวันที่คุณปรับสถานะ และดาวที่สะสมเพื่อแลกรับสิทธิประโยชน์จะมีอายุ 6 เดือน โดยเริ่มนับจากเดือนถัดไปหลังจากเดือนที่ได้รับดาวสะสมนั้นๆ</p>
                                    <h4 className="fs-6 fw-bold text-dark-000000">ข้อกำหนดและเงื่อนไข</h4>
                                    <p className="text-dark-212121"><Link to="/thtermsofuse">คลิกที่นี่</Link> เพื่อตรวจสอบข้อกำหนดและเงื่อนไขโปรแกรม สตาร์บัคส์ รีวอร์ด</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                <ThNavFooter />
            </>
        );
    }
}
import React, { Component } from 'react';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';

export class ThPromoGrabSrTerm extends Component {
    static displayName = ThPromoGrabSrTerm.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

    return (
        <>
            <ThNavHeader />

            <main id="main">
                <section className="top-sr-bg bg-gray1">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col col-md-6">
                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="text-black">สตาร์บัคส์ รีวอร์ด</span></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-dashboard overflow-hidden pb-5">
                    <div className="container mt-4">
                        <div className="row mb-5 px-2">
                            <div className="col-md-12">
                                <h2 className="h4 fw-semibold my-4">ข้อกำหนดและเงื่อนไขเกี่ยวกับการผูกบัญชีสมาชิก Grab และ Starbucks® Rewards</h2>
                                <div className="row mt-3 mb-4">
                                    <div className="col-md-12 content-term">
                                        <p>1 บัญชีสมาชิก Grab สามารถทำการผูกกับ 1 บัญชีสมาชิก Starbucks® Rewards ต่อครั้งเท่านั้น</p>
                                        <p>เมื่อทำการผูกบัญชีสมาชิกไว้ด้วยกันแล้ว จะสามารถสะสมดาวในโปรแกรม Starbucks® Rewards เมื่อมีการซื้อสินค้าสตาร์บัคส์ที่อยู่บนแอปพลิเคชัน Grab และเป็นร้านที่สร้างขึ้นโดย บริษัท คอฟฟี่ คอนเซ็ปต์ รีเทล จำกัด โดยทุก ๆ 50 บาทของการใช้จ่าย สมาชิก Starbucks® Rewards จะได้รับดาว Starbucks® Rewards 1 ดวง</p>
                                        <p>ยอดใช้จ่ายที่นำมาคำนวณดาว จะคำนวณจากยอดใช้จ่ายสุทธิในใบเสร็จที่ออกโดย บริษัท คอฟฟี่ คอนเซ็ปต์ รีเทล จำกัด เท่านั้นไม่รวมค่าบริการจัดส่ง</p>
                                        <p>การซื้อสินค้าสตาร์บัคส์ที่อยู่บนแอปพลิเคชัน Grab และเป็นร้านที่สร้างขึ้นโดย บริษัท คอฟฟี่ คอนเซ็ปต์ รีเทล จำกัด จะไม่เข้าร่วมกิจกรรมส่งเสริมการขายใด ๆ ที่เกิดขึ้น ณ ร้านสตาร์บัคส์ เว้นแต่สตาร์บัคส์จะกำหนดเป็นอย่างอื่นไว้เป็นการเฉพาะ และไม่ถือเป็นการใช้จ่าย ณ ร้านสตาร์บัคส์ เพื่อรับดาว หรือดาวโบนัส สำหรับการซื้อ ณ ร้านสตาร์บัคส์</p>
                                        <p>หากคุณมีบัญชีสมาชิก Starbucks® Rewards แล้ว กรุณาคลิกปุ่มด้านล่างซ้าย เพื่อทำการกรอกอีเมล และรหัสผ่านของบัญชีสมาชิก Starbucks® Rewards เพื่อผูกบัญชีสมาชิก Grab กับบัญชีสมาชิก Starbucks® Rewards หลังจากนั้นคุณสามารถสะสมดาวในโปรแกรม Starbucks® Rewards ได้</p>
                                        <p>หากคุณยังไม่มีบัญชีสมาชิก Starbucks® Rewards กรุณาคลิกที่ปุ่มด้านล่างขวา เพื่อสมัครลงทะเบียนสมาชิก Starbucks® Rewards และผูกบัญชีสมาชิก หลังจากนั้นคุณสามารถสะสมดาวในโปรแกรม Starbucks® Rewards ได้</p>
                                        <p>ในกรณีต้องการนำบัญชีสมาชิก Starbucks® Rewards ที่ผูกกับบัญชีสมาชิก Grab ไว้แล้ว ไปทำการผูกกับบัญชีสมาชิกอื่นของ Grab จะต้องทำการยกเลิกการผูกบัญชี Starbucks® Rewards เดิมก่อน จึงจะสามารถนำบัญชีสมาชิก Starbucks® Rewards ไปผูกกับบัญชีสมาชิกอื่นของ Grab ได้</p>
                                        <p>ในกรณีบัญชีสมาชิก Starbucks® Rewards หรือบัญชีสมาชิก Grab ถูกยกเลิกการเป็นสมาชิก ระบบจะทำการยกเลิกการผูกบัญชีสมาชิกนั้นทันที และท่านจะไม่สามารถสะสมดาวในโปรแกรม Starbucks® Rewards ผ่านการซื้อสินค้าสตาร์บัคส์ที่อยู่บนแอปพลิเคชัน Grab ในครั้งถัดไปได้</p>
                                        <p>ข้อกำหนดและเงื่อนไขนี้จัดทำขึ้นเป็นภาษาไทยและภาษาอังกฤษ ในกรณีที่มีข้อขัดแย้งระหว่างข้อกำหนดและเงื่อนไขนี้ฉบับภาษาอังกฤษ และฉบับภาษาไทย ให้ยึดตามฉบับภาษาอังกฤษ</p>
                                        <p>เงื่อนไขข้อกำหนดอื่น ๆ ระหว่างคุณกับสตาร์บัคส์ที่เกี่ยวข้องกับ 1) ข้อกำหนดการใช้ Starbucks® Rewards 2) ข้อกำหนดในการใช้และสัญญาบัญชี Starbucks Card และ 3) นโยบายคุ้มครองข้อมูลส่วนบุคคล ให้อยู่ภายใต้บังคับข้อกำหนดเงื่อนไขที่ระบุบนเว็บไซต์ของสตาร์บัคส์ประเทศไทย ดังนี้<br />
                                            1) <a href="https://rewards.starbucks.co.th/thtermsofuse" target="_blank">https://rewards.starbucks.co.th/thtermsofuse </a><br />
                                            2) <a href="https://rewards.starbucks.co.th/thterms" target="_blank">https://rewards.starbucks.co.th/thterms</a><br />
                                            3) <a href="https://www.starbucks.co.th/th/online-policies/privacy-statement/" target="_blank">https://www.starbucks.co.th/th/online-policies/privacy-statement/</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            

            <ThNavFooter />
      </>
    );
  }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';

export class ThPromoGrabSr extends Component {
    static displayName = ThPromoGrabSr.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

    return (
        <>
            <ThNavHeader />

            <main id="main">
                <section className="top-sr-bg bg-gray1">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col col-md-6">
                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="text-black">สตาร์บัคส์ รีวอร์ด</span></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-banner-grab p-4 p-md-0">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center align-items-center m-0 gx-5 text-white">
                            <div className="col-md-12 text-center p-0">
                                <div className="mb-0"><img src="../media/rewards/grab/banner-Grab-page.webp" alt="Banner" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-benefits pb-5">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center">
                            <div className="col-md-12 mb-4 pt-3 text-center">
                                <h2 className="h3 mt-4 fw-bold text-dark-212121">เพิ่มช่องทางให้คุณสะสมดาวเมื่อสั่งสตาร์บัคส์ผ่านแอปพลิเคชัน Grab</h2>
                            </div>
                            <div className="col-md-7">
                                <ul>
                                    <li>เพียงผูกบัญชี Starbucks&reg; Rewards กับ Grab รับดาวสะสม 1 ดวง จากการสั่งสตาร์บัคส์ผ่าน GrabFood ทุกๆ 50 บาท</li>
                                    <li>คุณจะได้รับดาวสะสม โดยคำนวณจากยอดรวมในใบเสร็จที่ออกโดยสตาร์บัคส์เท่านั้น ไม่รวมค่าบริการจัดส่ง</li>
                                    <li>ดาวสะสมจะเข้าสู่บัญชี Starbucks&reg; Rewards โดยอัตโนมัติหลังจากทำรายการเสร็จสมบูรณ์</li>
                                    <li>คุณสามารถตรวจสอบดาวสะสมของคุณผ่าน Starbucks &reg; TH app</li>
                                </ul>
                            </div>
                            <div className="col-md-7 text-center">
                                <div className="mt-4 mb-2"><img src="../media/rewards/grab/ICON-Grab-page.webp" alt="วิธีการผูกบัญชีสมาชิก Starbucks&reg; Rewards กับบัญชีสมาชิก Grab" className="img-fluid" width={100} /></div>
                                <h2 className="h3 mt-4 fw-bold text-dark-212121">วิธีการผูกบัญชีสมาชิก Starbucks&reg; Rewards กับบัญชีสมาชิก Grab</h2>
                            </div>
                        </div>
                        <nav>
                            <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="already-joined-tab" data-bs-toggle="tab" data-bs-target="#already-joined" type="button" role="tab" aria-controls="already-joined" aria-selected="true">
                                    มีบัญชีสมาชิก Starbucks&reg; Rewards แล้ว</button>
                                <button className="nav-link" id="not-yet-registered-tab" data-bs-toggle="tab" data-bs-target="#not-yet-registered" type="button" role="tab" aria-controls="not-yet-registered" aria-selected="false">
                                    ไม่มีบัญชีสมาชิก Starbucks&reg; Rewards</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="already-joined" role="tabpanel" aria-labelledby="already-joined-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-md-12 text-center px-4 px-md-0">
                                            <h3 className="h3 mt-2 fw-bold text-green-1">กดเข้าแอปพลิเคชัน Grab เพื่อเริ่มต้นการใช้งาน</h3>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab1_01_TH.webp" alt="ก่อนทำการสั่งซื้อ" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>01</h3>
                                                <p>ก่อนทำการสั่งซื้อ – ที่โปรแกรมสมาชิก คลิก “เพิ่มหรือสมัคร”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab1_02_TH.webp" alt="อ่านรายละเอียด คลิกยอมรับเงื่อนไข" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>02</h3>
                                                <p>อ่านรายละเอียด คลิกยอมรับเงื่อนไข และคลิก “เป็นสมาชิก”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab1_03_TH.webp" alt="กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก Starbucks Rewards" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>03</h3>
                                                <p>กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก Starbucks&reg; Rewards</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab1_04_TH.webp" alt="ผูกบัญชีสำเร็จ" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>04</h3>
                                                <p>ผูกบัญชีสำเร็จ ที่หน้าสรุปคำสั่งซื้อจะมีเครื่องหมายถูกสีเขียวปรากฏอยู่ที่หลังคำว่า “Membership”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab1_05_TH.webp" alt="เลือกวิธีชำระเงินที่ต้องการ" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>05</h3>
                                                <p>เลือกวิธีชำระเงินที่ต้องการ - คุณจะได้รับการสะสมดาวทุกวิธีการชำระเงิน</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="not-yet-registered" role="tabpanel" aria-labelledby="not-yet-registered-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-md-12 text-center px-4 px-md-0">
                                            <h3 className="h3 mt-2 fw-bold text-green-1">กดเข้าแอปพลิเคชัน Grab เพื่อเริ่มต้นการใช้งาน</h3>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab2_01_TH.webp" alt="ก่อนทำการสั่งซื้อ" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>01</h3>
                                                <p>ก่อนทำการสั่งซื้อ – ที่โปรแกรมสมาชิก คลิก “เพิ่มหรือสมัคร”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab2_02_TH.webp" alt="อ่านรายละเอียด คลิกยอมรับเงื่อนไข" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>02</h3>
                                                <p>อ่านรายละเอียด คลิกยอมรับเงื่อนไข และคลิก “ไม่เป็นสมาชิก/No”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab2_03_TH.webp" alt="กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก Starbucks Rewards" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>03</h3>
                                                <p>กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก Starbucks&reg; Rewards</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab2_04_TH.webp" alt="ค้างหน้าจอนี้ไว้" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>04</h3>
                                                <p>ค้างหน้าจอนี้ไว้ - ตรวจสอบอีเมลของคุณ และกด Verification link หลังจากนั้นกรอกข้อมูล เพื่อสมัครสมาชิก Starbucks&reg; Rewards</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab2_05_TH.webp" alt="ระบบกำลังทำการผูกบัญชีกรุณาค้างหน้าจอนี้ไว้ 5 วินาที" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>05</h3>
                                                <p>ระบบกำลังทำการผูกบัญชีกรุณาค้างหน้าจอนี้ไว้ 5 วินาที แล้วจึงทำการปิดหน้าจอนี้ หากผูกบัญชีสำเร็จ บนหน้าสรุปคำสั่งซื้อจะมีเครื่องหมายถูกสีเขียวปรากฏอยู่ที่หลังคำว่า “Membership”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/grab/tab2_06_TH.webp" alt="เลือกวิธีชำระเงินที่ต้องการ - คุณจะได้รับการสะสมดาวทุกวิธีการชำระเงิน" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>06</h3>
                                                <p>เลือกวิธีชำระเงินที่ต้องการ - คุณจะได้รับการสะสมดาวทุกวิธีการชำระเงิน</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-faq pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-4 mb-md-5 pt-3">
                                <h3 className="h3 mt-3 fw-bold text-dark-000000"><strong>ข้อกำหนดและเงื่อนไข</strong></h3>
                                <p>สำหรับรายละเอียดทั้งหมดเกี่ยวกับโปรแกรม โปรดอ่านข้อกำหนดการใช้งานของเรา <Link to="/thpromograbsrterm">ที่นี่</Link></p>
                                <h3 className="h3 mt-5 fw-bold text-dark-000000"><strong>คำถามที่พบบ่อย</strong></h3>
                                <div className="accordion mt-3" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse1" aria-expanded="false" aria-controls="panelsStayOpen-collapse1">
                                                จะได้รับดาวสะสมจากคำสั่งซื้อสตาร์บัคส์บนแอปพลิเคชัน Grab ได้อย่างไร?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse1" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ทำการผูกบัญชี Starbucks&reg; Rewards กับบัญชีสมาชิก Grab บนแอปพลิเคชัน Grab</li>
                                                    <li>ไปที่เมนูอาหารบนแอปพลิเคชัน Grab และค้นหาร้านสตาร์บัคส์ เพื่อสั่งซื้อ</li>
                                                    <li>ทำการสั่งซื้อและในหน้าสรุปคำสั่งซื้อ ให้เลื่อนลงไปที่ “โปรแกรมสมาชิก” กด ”เพิ่มหรือสมัคร”</li>
                                                    <li>เลื่อนลงไปด้านล่าง กด “รับทราบข้อตกลงและเงื่อนไข” หลังจากนั้นให้กด “เป็นสมาชิก / Yes” และพิมพ์รายละเอียด และกด “เข้าสู่ระบบ และผูกบัญชีสมาชิก”</li>
                                                    <li>เมื่อคุณได้รับการแจ้งเตือนว่าการผูกบัญชีของคุณสำเร็จ บนหน้าสรุปคำสั่งซื้อจะมีเครื่องหมายถูกสีเขียวปรากฏอยู่ที่หลังคำว่า “Membership”</li>
                                                    <li>คุณจะเริ่มสะสมดาวจากการสั่งซื้อบนแอปพลิเคชัน Grab ที่ทำการชำระเงินสำเร็จ ไม่จำเป็นต้องผูกบัญชีทุกครั้งที่คุณสั่งสตาร์บัคส์บนแอปพลิเคชัน Grab</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse2" aria-expanded="false" aria-controls="panelsStayOpen-collapse2">
                                                หากไม่มีบัญชี Starbucks&reg; Rewards สามารถลงทะเบียนได้อย่างไร?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse2" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ดาวน์โหลดแอปสตาร์บัคส์ประเทศไทย หรือเข้าไปที่เว็บไซต์สตาร์บัคส์ประเทศไทย เพื่อลงทะเบียนสมาชิก Starbucks&reg; Rewards ของคุณ</li>
                                                    <li>ทำการลงทะเบียนผ่านแอปพลิเคชัน Grab ดังนี้
                                                        <ul>
                                                            <li>ไปที่เมนูอาหารบนแอปพลิเคชัน Grab และค้นหาร้านสตาร์บัคส์ เพื่อสั่งซื้อ</li>
                                                            <li>ในหน้าสรุปคำสั่งซื้อ ให้เลื่อนลงไปที่ “โปรแกรมสมาชิก” กด “เพิ่มหรือสมัคร”</li>
                                                            <li>เลื่อนลงไปด้านล่าง กด “รับทราบข้อตกลงและเงื่อนไข” หลังจากนั้นให้กดที่ “ไม่เป็นสมาชิก / No” และพิมพ์อีเมลที่ใช้ในการลงทะเบียน และกด “ลงทะเบียน”</li>
                                                            <li>ตรวจสอบอีเมลของคุณและกด “Verification link”</li>
                                                            <li>พิมพ์ข้อมูลเพื่อทำการลงทะเบียน และกด “ลงทะเบียน”</li>
                                                            <li>เมื่อคุณได้รับการแจ้งเตือนว่าการผูกบัญชีของคุณสำเร็จ ให้กลับไปที่แอปพลิเคชัน Grab</li>
                                                            <li>ระบบกำลังทำการผูกบัญชีกรุณาค้างหน้าจอนี้ไว้ 5 วินาที แล้วจึงทำการปิดหน้าจอนี้</li>
                                                            <li>หากผูกบัญชีสำเร็จ บนหน้าสรุปคำสั่งซื้อจะมีเครื่องหมายถูกสีเขียวปรากฏอยู่ที่หลังคำว่า “Membership”</li>
                                                            <li>คุณจะเริ่มสะสมดาวจากการสั่งซื้อบนแอปพลิเคชัน Grab ที่ทำการชำระเงินสำเร็จ ไม่จำเป็นต้องผูกบัญชีทุกครั้งที่คุณสั่งสตาร์บัคส์บนแอปพลิเคชัน Grab</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse3" aria-expanded="false" aria-controls="panelsStayOpen-collapse3">
                                                สามารถสะสมดาวจากยอดค่าใช้จ่ายส่วนอื่นบนแอปพลิเคชัน Grab ได้หรือไม่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse3" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ไม่ได้ ดาวจะได้รับเมื่อสั่งสตาร์บัคส์จากร้านของสตาร์บัคส์บนแอปพลิเคชัน Grab เท่านั้น</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
                                                สมาชิกสามารถชำระเงินโดยใช้บัตร Starbucks Card เพื่อสะสมดาวจากยอดค่าใช้จ่ายผ่านแอปพลิเคชัน Grab หรือไม่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse4" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ไม่ได้ คุณสามารถสะสมดาวโดยเลือกวิธีการชำระเงินที่มีให้ในแอปพลิเคชัน Grab เท่านั้นและได้รับดาวสะสมตามเงื่อนไขที่กำหนด</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
                                                เมื่อใช้จ่ายผ่านแอปพลิเคชัน Grab จะได้รับดาวสะสมกี่ดวง?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse5" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>คุณจะได้รับ 1 ดาวสำหรับทุกๆการใช้จ่าย 50 บาท ที่ทำการสั่งซื้อสตาร์บัคส์จากร้านสตาร์บัคส์บนแอปพลิเคชัน Grab หลังจากผูกบัญชี Starbucks&reg; Rewards กับบัญชีสมาชิก Grab สำเร็จ</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse6" aria-expanded="false" aria-controls="panelsStayOpen-collapse6">
                                                สามารถสะสมดาวจากค่าจัดส่งและส่วนลดได้หรือไม่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse6" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ค่าจัดส่งและส่วนลดส่งเสริมการขายจะไม่ได้รับการสะสมดาว</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse7" aria-expanded="false" aria-controls="panelsStayOpen-collapse7">
                                                สามารถตรวจสอบยอดดาวสะสมจากการใช้จ่ายสตาร์บัคส์บนแอปพลิเคชัน Grab ได้ที่ไหน?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse7" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ได้ที่แอปสตาร์บัคส์ประเทศไทย หรือบัญชีสมาชิกของคุณในเว็ปไซต์สตาร์บัคส์ประเทศไทย</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse8" aria-expanded="false" aria-controls="panelsStayOpen-collapse8">
                                                ดาวสะสมจะถูกนำเข้าบัญชี Starbucks&reg; Rewards เมื่อไหร่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse8" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ดาวสะสมจะเข้าบัญชี Starbucks&reg; Rewards ของคุณหลังจากที่คุณชำระเงินและได้รับสินค้าตามคำสั่งซื้อเรียบร้อยภายใน 24 ชั่วโมง</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse9" aria-expanded="false" aria-controls="panelsStayOpen-collapse9">
                                                สามารถแลกรับสิทธิประโยชน์อะไรได้บ้างจากการสะสมดาว
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse9" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>สามารถตรวจสอบสิทธิประโยชน์ของสมาชิก Starbucks&reg; Rewards ที่ <a href="https://www.starbuckscardth.in.th/THHome" target="_blank">https://www.starbuckscardth.in.th/THHome</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse10" aria-expanded="false" aria-controls="panelsStayOpen-collapse10">
                                                ถ้าต้องการยกเลิกการผูกบัญชีสมาชิก Starbucks&reg; Rewards กับบัญชีสมาชิก Grab ต้องทำอย่างไร
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse10" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ในหน้าสรุปคำสั่งซื้อ ไปที่ “Membership” กดที่เครื่องหมายเฟืองด้านบนขวา กดที่ “ยกเลิกการผูกบัญชีสมาชิก” กด “ลบออก”</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse11" aria-expanded="false" aria-controls="panelsStayOpen-collapse11">
                                                ถ้ามีการเปลี่ยนอีเมลหรือรหัสผ่านของบัญชี Starbucks&reg; Rewards จะต้องเปลี่ยนทุกแพลตฟอร์มที่เชื่อมความสัมพันธ์ไว้ด้วยหรือไม่
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse11" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>หากมีการเปลี่ยนแปลงข้อมูลบัญชีสมาชิก Starbucks&reg;Rewards เช่น อีเมลหรือรหัสผ่าน ระบบจะทำการปลดความสัมพันธ์จากทุกแพลตฟอร์มที่มีการใช้ข้อมูลส่วนนี้ คุณจะต้องทำการเข้าระบบใหม่อีกครั้งด้วยอีเมลหรือรหัสผ่านที่ทำการเปลี่ยนแปลงเพื่อผูกความสัมพันธ์อีกครั้ง (ดูวิธีผูกบัญชีได้ที่ “วิธีการผูกบัญชีสมาชิก Starbucks&reg; Rewards กับบัญชี Grab”</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse12" aria-expanded="false" aria-controls="panelsStayOpen-collapse12">
                                                ถ้าต้องการเปลี่ยนแปลงบัญชีสมาชิก Starbucks&reg; Rewards ที่เชื่อมโยงกับบัญชีสมาชิก Grab ต้องทำอย่างไร
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse12" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ทำตามขั้นตอนของข้อด้านบน แล้วทำการผูกบัญชีสมาชิก Starbucks&reg; Rewards ใหม่ (ดูวิธีผูกบัญชีได้ที่ “วิธีการผูกบัญชีสมาชิก Starbucks&reg; Rewards กับบัญชี Grab” และดาวสะสมจะเข้าในบัญชี Starbucks&reg; Rewards ใหม่</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse13" aria-expanded="false" aria-controls="panelsStayOpen-collapse13">
                                                หากพบปัญหาเกี่ยวกับการใช้บริการ Grab
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse13" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>สามารถดูวิธีแก้ไขและรายงานปัญหาด้วยตัวเองได้ที่ “ศูนย์ช่วยเหลือ” หรือ คลิกที่นี่&nbsp;<a href="https://help.grab.com/passenger/th-th/115015916667" target="_blank">https://help.grab.com/passenger/th-th/115015916667</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse14" aria-expanded="false" aria-controls="panelsStayOpen-collapse14">
                                                หากพบปัญหาเกี่ยวกับการสะสมดาวผ่าน Grab
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse14" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>สอบถามรายละเอียดเพิ่มเติม กรุณาติดต่อ Starbucks Customer Care 02-339-0996 (จันทร์ - อาทิตย์ 9:00 – 18:00 น.)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse15" aria-expanded="false" aria-controls="panelsStayOpen-collapse15">
                                                I have a concern with the Grab app.
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse15" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>For any concerns, access the “Help Centre” in the Grab app or visit&nbsp;<u><a href="https://help.grab.com/passenger/th-th/115015916667">https://help.grab.com/passenger/th-th/115015916667</a></u>.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse16" aria-expanded="false" aria-controls="panelsStayOpen-collapse16">
                                                I have a concern with Stars earned on the Grab app.
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse16" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>For any concerns with Stars collected, you can contact our Starbucks Customer Care at +66 2339 0996 from 9.00 a.m. – 6.00 p.m. Monday to Sunday</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>            

            <ThNavFooter />
      </>
    );
  }
}

import React, { Component } from 'react';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

export class EnPromoGrabSrTerm extends Component {
    static displayName = EnPromoGrabSrTerm.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

    return (
        <>
            <EnNavHeader />

            <main id="main">
                <section className="top-sr-bg bg-gray1">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col col-md-6">
                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks® Rewards</span><img src="media/rewards/starbucks-rewards-logo-2.png" alt="Starbucks® Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-dashboard overflow-hidden pb-5">
                    <div className="container mt-4">
                        <div className="row mb-5 px-2">
                            <div className="col-md-12">
                                <h2 className="h4 fw-semibold my-4">Terms and conditions for linking Grab account to Starbucks® Rewards Program</h2>
                                <div className="row mt-3 mb-4">
                                    <div className="col-md-12 content-term">
                                        <p>One (1) Grab account can only be linked to One (1) Starbucks® Rewards account at a time.</p>
                                        <p>When you have linked your Grab account with Starbucks® Rewards program, you will earn 1 Star on every 50 Baht spent on Starbucks products from Starbucks shop created by Coffee Concept Retail Co., Ltd. on Grab Application.</p>
                                        <p>The Stars will be calculated from the total amount shown on the original receipt issue by Coffee Concept Retail Co., Ltd. Only exclude delivery fee.</p>
                                        <p>Any promotional activities that take place at Starbucks stores shall not apply to all purchases of Starbucks products from Starbucks shop created by Coffee Concept Retail Co., Ltd. on Grab Application unless otherwise indicated specifically by Starbucks. Such purchases shall not be qualified to earn same number of Stars or Bonus Stars as spending in Starbucks stores.</p>
                                        <p>If you are a member of Starbucks® Rewards program, please click on the button on the bottom left to log in your “Starbucks® Rewards” account with your username (email) and password and link your Grab account with Starbucks® Rewards program. Afterwards, you will earn Star from Starbucks® Rewards program on your Starbucks order via Grab Application.</p>
                                        <p>If you are not a member of Starbucks® Rewards program, please click on the button on the bottom right to register for your Starbucks® Rewards account and link your Grab account with Starbucks® Rewards program. Afterwards, you will earn Star from Starbucks® Rewards program on your Starbucks order via Grab Application.</p>
                                        <p>You can reapply to another Grab account with the existing Starbucks® Rewards account by unlinking your current Grab account with your Starbucks® Rewards account.</p>
                                        <p>In the event that your Starbucks® Rewards account or Grab account is terminated, the system will immediately delink the two accounts and you will not be entitled to earn Starbucks® Rewards Stars earned through your next Starbucks purchase on the Grab Application.</p>
                                        <p>These terms and conditions were made in Thai and English. In case of any conflict between the provisions of the English version of these Terms and Conditions and Thai version, the English version will prevail.</p>
                                        <p>Other terms and condition in connection with 1) Starbucks® Rewards 2) Starbucks Card Account Terms of Use and Agreement; and 3) Starbucks Privacy Notice shall be governed under the standard terms and conditions set forth on Starbucks Thailand website with the following URL:<br />
                                            1) <a href="https://rewards.starbucks.co.th/termsofuse" target="_blank">https://rewards.starbucks.co.th/termsofuse</a><br />
                                            2) <a href="https://rewards.starbucks.co.th/terms" target="_blank">https://rewards.starbucks.co.th/terms</a><br />
                                            3)<a href="https://www.starbucks.co.th/online-policies/privacy-statement/" target="_blank"> https://www.starbucks.co.th/online-policies/privacy-statement/</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            

            <EnNavFooter />
      </>
    );
  }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

export class EnPromoLineMan extends Component {
    static displayName = EnPromoLineMan.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

    return (
        <>
            <EnNavHeader />

            <main id="main">
                <section className="top-sr-bg bg-gray1">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col col-md-6">
                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks® Rewards</span><img src="media/rewards/starbucks-rewards-logo-2.png" alt="Starbucks® Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-banner-LINE MAN p-4 p-md-0">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center align-items-center m-0 gx-5 text-white">
                            <div className="col-md-12 text-center p-0">
                                <div className="mb-0"><img src="media/rewards/lineman/Lineman_WB2.webp" alt="Banner" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-benefits pb-5">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center">
                            <div className="col-md-12 mb-4 pt-3 text-center">
                                <h2 className="h3 mt-4 fw-bold text-dark-212121">More convenient ways to collect Stars on LINE MAN</h2>
                            </div>
                            <div className="col-md-10">
                                <ul>
                                    <li>Link your Starbucks® Rewards and LINE MAN accounts to start earning 1 Star for every 50 Baht spent on Starbucks orders on LINE MAN.</li>
                                    <li>Stars will be calculated from the Starbucks receipt, exclude delivery fees.</li>
                                    <li>Stars will be added up to your Starbucks® Rewards account automatically, once you’ve completed your order.</li>
                                    <li>You can check your Stars collected at Starbucks® TH app.</li>
                                </ul>
                            </div>
                            <div className="col-md-11 text-center">
                                <div className="mt-4 mb-2"><img src="media/rewards/lineman/ICON-line-man-page.webp" alt="How to link Starbucks® Rewards and LINE MAN account" className="img-fluid" width={100} /></div>
                                <h2 className="h3 mt-4 fw-bold text-dark-212121">How to link Starbucks® Rewards and LINE MAN account</h2>
                            </div>
                        </div>
                        <nav>
                            <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="already-joined-tab" data-bs-toggle="tab" data-bs-target="#already-joined" type="button" role="tab" aria-controls="already-joined" aria-selected="true">
                                    Already joined Starbucks® Rewards member</button>
                                <button className="nav-link" id="not-yet-registered-tab" data-bs-toggle="tab" data-bs-target="#not-yet-registered" type="button" role="tab" aria-controls="not-yet-registered" aria-selected="false">
                                    Not yet registered Starbucks® Rewards member</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="already-joined" role="tabpanel" aria-labelledby="already-joined-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-md-12 text-center px-4 px-md-0">
                                            <h3 className="h3 mt-2 fw-bold text-green-1">Tap on the LINE MAN app to get started.</h3>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-1-First-Page.png" alt="Before Place Order" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-2-Add-now.png" alt="Add or join now" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>01</h3>
                                                <p>Click “Add or Join now”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-3-Join-now1.png" alt="Log in Starbucks@ Rewards Account details." className="img-fluid" /></div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-4-Term-yes.png" alt="Read terms and conditions" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>02</h3>
                                                <p>Read terms and conditions and click <br />“เป็นสมาชิก/Yes”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-5-sign-in.png" alt="Log in Starbucks@ Rewards Account details." className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>03</h3>
                                                <p>Log in Starbucks® Rewards Account.</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-6-link-sr-done.png" alt="Linking Successful." className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>04</h3>
                                                <p>Linking Successful. Click “Back to LINE MAN”</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-7-already-Join.png" alt="Linking Successful" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-8-Already-join-2.png" alt="After Successful Linking green check mark" className="img-fluid" /></div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="my-4">
                                                <h3>05</h3>
                                                <p>Green check mark will appear after “Starbucks® Rewards" on Restaurant and Order Summary page.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="not-yet-registered" role="tabpanel" aria-labelledby="not-yet-registered-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-md-12 text-center px-4 px-md-0">
                                            <h3 className="h3 mt-2 fw-bold text-green-1">Tap on the LINE MAN app to get started.</h3>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-1-First-Page.png" alt="Before Place Order" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-2-Add-now.png" alt="Click Add or join now" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>01</h3>
                                                <p>Click “Add or Join now”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-3-Join-now1.png" alt="Log in Starbucks@ Rewards Account details." className="img-fluid" /></div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-4-Term-no.png" alt="Read terms and conditions" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>02</h3>
                                                <p>Read terms and conditions and click <br />“ไม่เป็นสมาชิก/No”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-5-2-Creaste-account.png" alt="Fill in your email to register Starbucks® Rewards." className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>03</h3>
                                                <p>Fill in your email to register <br />Starbucks® Rewards.</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-6-link-and-register.png" alt="Check your email and click on verification link and register." className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>04</h3>
                                                <p>Check your email and click on verification link and register.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-7-already-Join.png" alt="Linking Successful" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/lineman/en-8-Already-join-2.png" alt="After Successful" className="img-fluid" /></div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="my-4">
                                                <h3>05</h3>
                                                <p>After Successful Linking green check mark will appear after “Starbucks® Rewards" on Restaurant and Order Summary page.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-faq pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-4 mb-md-5 pt-3">
                                <h3 className="h3 mt-3 fw-bold text-dark-000000"><strong>Terms &amp; Conditions</strong></h3>
                                <p>For full details on the program, read our terms of use&nbsp;<Link to="/promolinemanterm">here</Link>.</p>
                                <h3 className="h3 mt-5 fw-bold text-dark-000000"><strong>Frequently Asked Questions</strong></h3>
                                <div className="accordion mt-3" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse1" aria-expanded="false" aria-controls="panelsStayOpen-collapse1">
                                                How can I collect Stars on Starbucks orders in LINE MAN app?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse1" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>To collect Stars on Starbucks orders in LINE MAN app, members must link their Starbucks® Rewards account to a LINE MAN account.</li>
                                                </ul>
                                                <p>Link account for the first time:</p>
                                                <ul>
                                                    <li>Go to the Food tile on the LINE MAN app and search for Starbucks to order.</li>
                                                    <li>Go to the Home page and click “Add or Join now” or once you’ve selected your order, go to the “Membership and loyalty” section on the Order Summary page.  Click “Add or join now”, click “I have read and agreed to the Term &amp; Conditions”, click “เป็นสมาชิก/Yes” and log-in your Starbucks® Rewards account.</li>
                                                    <li>Once you receive a notification confirming the successful linkage of your Starbucks® Rewards and LINE MAN accounts, a green check mark will appear next to "Starbucks® Rewards" (on the Home page and the Order Summary page).</li>
                                                    <li>You will start earning Stars on all completed LINE MAN delivery orders. You don’t  need to link accounts every time you order Starbucks in LINE MAN.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse2" aria-expanded="false" aria-controls="panelsStayOpen-collapse2">
                                                How do I register for a Starbucks® Rewards account if I’m not already a member?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse2" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Download the Starbucks® TH app or go to Starbucks Thailand website to register your Starbucks® Rewards account.</li>
                                                    <li>Or register Starbucks Rewards account through LINE MAN app.</li>
                                                    <li>Go to the Food tile on the LINE MAN app and search for Starbucks to order.</li>
                                                    <li>Go to the Home page and click “Add or Join now” or once you’ve selected your order and go to the ‘Loyalty and Membership’ section on the Order Summary page. Click “Add or join now”, click “I have read and agreed to the Term &amp; Conditions.” and click “ไม่เป็นสมาชิก/No”.</li>
                                                    <li>Key-in your valid &amp; active email address to register and click “Sign in &amp; link your account.”</li>
                                                    <li>Check your email and click at the verification link.</li>
                                                    <li>To register, please fill in all required information and click “Create account.” </li>
                                                    <li>Once you’ve received a notification that you’ve successfully linked your Starbucks® Rewards and LINE MAN account, please go back to LINE MAN app to continue your order.</li>
                                                    <li>There should be a check green mark after “Starbucks® Rewards” in LINE MAN app.</li>
                                                    <li>you will start earning Stars on all completed LINE MAN delivery orders. You don’t need to link accounts every time you order Starbucks in LINE MAN.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse3" aria-expanded="false" aria-controls="panelsStayOpen-collapse3">
                                                Can I collect Stars from other establishment on LINE MAN app?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse3" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>No. Stars are only earned from the Starbucks order only.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
                                                Can I pay with a Starbucks Card when order Starbucks on LINE MAN app to earn Stars?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse4" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>No.  You can not pay with a Starbucks Card on LINE MAN app hence you will earn Stars from any payment options available in LINE MAN app.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
                                                How many Stars will I earn from Starbucks orders on LINE MAN app?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse5" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>You’ll earn 1 Star for each 50 Baht spent on Starbucks orders on LINE MAN app after successfully linking your Starbucks® Rewards and LINE MAN accounts.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse6" aria-expanded="false" aria-controls="panelsStayOpen-collapse6">
                                                Will I collect Stars on delivery fees and discounts?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse6" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Delivery fees and promotional discounts will not earn Stars. </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse7" aria-expanded="false" aria-controls="panelsStayOpen-collapse7">
                                                Where can I track the Stars earned on Starbucks orders in LINE MAN app?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse7" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>You can check your Stars earned at Starbucks® TH app or Starbucks Thailand website.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse8" aria-expanded="false" aria-controls="panelsStayOpen-collapse8">
                                                When will Stars be credited to my Starbucks® Rewards account?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse8" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Stars earned will be credited to your Starbucks® Rewards account within 24 hours after your order is successfully delivered.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse9" aria-expanded="false" aria-controls="panelsStayOpen-collapse9">
                                                What rewards can I redeem with Stars?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse9" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>You can check your rewards as a Starbucks® Rewards member at&nbsp;<a href="https://rewards.starbucks.co.th/" target="_blank"><strong>https://rewards.starbucks.co.th/</strong></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse10" aria-expanded="false" aria-controls="panelsStayOpen-collapse10">
                                                Can I use Stars to redeem LINE MAN Rewards?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse10" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>No. Stars earned can only be used to redeem a benefit on Starbucks® Rewards program.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse11" aria-expanded="false" aria-controls="panelsStayOpen-collapse11">
                                                Will Stars or Rewards expire?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse11" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Yes. As a Starbucks® Rewards member, you can check the expiration of your Stars &amp; Rewards at&nbsp;<a href="https://rewards.starbucks.co.th/" target="_blank"><strong>https://rewards.starbucks.co.th/</strong></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse12" aria-expanded="false" aria-controls="panelsStayOpen-collapse12">
                                                How can I unlink Starbucks® Rewards account with LINE MAN account?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse12" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>To unlink the account, please click at "Learn more", click at the three-dot icon and followed by "Manage account" button.  Then click at "Unlink account" and confirm by click at "Unlink now" button.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse14" aria-expanded="false" aria-controls="panelsStayOpen-collapse14">
                                                How can I change Starbucks® Rewards account which link to LINE MAN account?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse14" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Please unlink your Starbucks Rewards account with LINE MAN account by following the steps from above question. Once you’ve completed, please relink your LINE MAN account with the new Starbucks® Rewards account to earn Stars.</li></ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse15" aria-expanded="false" aria-controls="panelsStayOpen-collapse15">
                                                I have a concern with the LINE MAN app.
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse15" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>For any concerns, access the “Help Centre” in the LINE MAN app or visit&nbsp;<a href="https://lineman.onelink.me/1N3T/e2kg5ijl" target="_blank">https://lineman.onelink.me/1N3T/e2kg5ijl</a></li>
                                                    <li> For any concerns with Stars       collected, you can contact our Starbucks Customer Care at +66 2339 0996       from 9.00 a.m. – 6.00 p.m. Monday to Sunday</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            

            <EnNavFooter />
      </>
    );
  }
}

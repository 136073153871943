import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

export class EnPromoGrabSr extends Component {
    static displayName = EnPromoGrabSr.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

    return (
        <>
            <EnNavHeader />

            <main id="main">
                <section className="top-sr-bg bg-gray1">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col col-md-6">
                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="d-none">Starbucks&reg; Rewards</span><img src="media/rewards/starbucks-rewards-logo-2.png" alt="Starbucks&reg; Rewards" className="img-fluid" style={{ maxWidth: '158px' }} /></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-banner-grab p-4 p-md-0">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center align-items-center m-0 gx-5 text-white">
                            <div className="col-md-12 text-center p-0">
                                <div className="mb-0"><img src="media/rewards/grab/banner-Grab-page.webp" alt="Banner" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-benefits pb-5">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center">
                            <div className="col-md-12 mb-4 pt-3 text-center">
                                <h2 className="h3 mt-4 fw-bold text-dark-212121">More convenient ways to collect Stars on Grab</h2>
                            </div>
                            <div className="col-md-7">
                                <ul>
                                    <li>Link your Starbucks&reg; Rewards and Grab accounts to start earning 1 Star for every 50 Baht spent on Starbucks orders on GrabFood.</li>
                                    <li>Stars will be calculated from the Starbucks receipt, exclude delivery fees.</li>
                                    <li>Stars will be added up to your Starbucks&reg; Rewards account automatically, once you’ve completed your order.</li>
                                    <li>You can check your Stars collected at Starbucks &reg; TH app.</li>
                                </ul>
                            </div>
                            <div className="col-md-7 text-center">
                                <div className="mt-4 mb-2"><img src="media/rewards/grab/ICON-Grab-page.webp" alt="How to link Starbucks&reg; Rewards and Grab account" className="img-fluid" width={100} /></div>
                                <h2 className="h3 mt-4 fw-bold text-dark-212121">How to link Starbucks&reg; Rewards and Grab account</h2>
                            </div>
                        </div>
                        <nav>
                            <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="already-joined-tab" data-bs-toggle="tab" data-bs-target="#already-joined" type="button" role="tab" aria-controls="already-joined" aria-selected="true">
                                    Already joined Starbucks&reg; Rewards member</button>
                                <button className="nav-link" id="not-yet-registered-tab" data-bs-toggle="tab" data-bs-target="#not-yet-registered" type="button" role="tab" aria-controls="not-yet-registered" aria-selected="false">
                                    Not yet registered Starbucks&reg; Rewards member</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="already-joined" role="tabpanel" aria-labelledby="already-joined-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-md-12 text-center px-4 px-md-0">
                                            <h3 className="h3 mt-2 fw-bold text-green-1">Tap on the Grab app to get started.</h3>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab1_01_EN.webp" alt="Before Place Order" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>01</h3>
                                                <p>Before Place Order – at Loyalty and memberships, click “Add or join now”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab1_02_EN.webp" alt="Read terms and conditions" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>02</h3>
                                                <p>Read terms and conditions and click “เป็นสมาชิก/Yes”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab1_03_EN.webp" alt="Log in Starbucks@ Rewards Account details." className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>03</h3>
                                                <p>Log in Starbucks@ Rewards Account details.</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab1_04_EN.webp" alt="Linking Successful" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>04</h3>
                                                <p>Linking Successful. Green check mark will appear after "Membership" on Order Summary page.</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab1_05_EN.webp" alt="Pay how you’d like on Grab" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>05</h3>
                                                <p>Pay how you’d like on Grab – you’ll collet Stars all ways.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="not-yet-registered" role="tabpanel" aria-labelledby="not-yet-registered-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-md-12 text-center px-4 px-md-0">
                                            <h3 className="h3 mt-2 fw-bold text-green-1">Tap on the Grab app to get started.</h3>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab2_01_EN.webp" alt="Before Place Order" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>01</h3>
                                                <p>Before Place Order – at Loyalty and memberships, click “Add or join now”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab2_02_EN.webp" alt="Read terms and conditions" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>02</h3>
                                                <p>Read terms and conditions and click “ไม่เป็นสมาชิก/No”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab2_03_EN.webp" alt="Fill in your email to register Starbucks&reg; Rewards." className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>03</h3>
                                                <p>Fill in your email to register Starbucks&reg; Rewards.</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab2_04_EN.webp" alt="Stay on this page" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>04</h3>
                                                <p>Stay on this page - check your email and click on verification link and register.</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab2_05_EN.webp" alt="Account linking is in progress" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>05</h3>
                                                <p>Account linking is in progress. Please do not close this page.If your account is successfully linked, green check mark will appear after "Membership“ on Order Summary page.</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="media/rewards/grab/tab2_06_EN.webp" alt="Pay how you’d like on Grab" className="img-fluid" /></div>
                                            <div className="mt-0 mb-4">
                                                <h3>06</h3>
                                                <p>Pay how you’d like on Grab – you’ll collet Stars all ways.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-faq pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-4 mb-md-5 pt-3">
                                <h3 className="h3 mt-3 fw-bold text-dark-000000"><strong>Terms &amp; Conditions</strong></h3>
                                <p>For full details on the program, read our terms of use&nbsp;<Link to="/promograbsrterm">here</Link>.</p>
                                <h3 className="h3 mt-5 fw-bold text-dark-000000"><strong>Frequently Asked Questions</strong></h3>
                                <div className="accordion mt-3" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse1" aria-expanded="false" aria-controls="panelsStayOpen-collapse1">
                                                How can I collect Stars on Starbucks orders on Grab app?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse1" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>To collect Stars on Starbucks orders on Grab app, members must link their Starbucks&reg; Rewards account to a Grab app account.<br />
                                                        To link accounts for the first time:</li>
                                                    <li>Go to the Food tile on the Grab app and search for Starbucks to order.</li>
                                                    <li>Once you’ve selected your order, go to the ‘Loyalty and Membership’ section on the Order Summary page. Click ‘Add or join now’ and click “I have read and agreed to the Term &amp; Conditions.” and &nbsp;“เป็นสมาชิก/Yes” and log-in your Starbucks&reg; Rewards account.</li>
                                                    <li>Once you’ve received a notification that you’ve successfully linked your Starbucks&reg; Rewards and Grab account, green check mark will appear after "Membership" on Order Summary page.</li>
                                                    <li>you will start earning Stars on all completed Grab delivery orders. There’s no need to link accounts every time you order Starbucks on Grab.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse2" aria-expanded="false" aria-controls="panelsStayOpen-collapse2">
                                                How do I register for a Starbucks&reg; Rewards account if I’m not already a member?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse2" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Download the Starbucks&reg; TH app or go to Starbucks Thailand website to register your Starbucks&reg; Rewards account.</li>
                                                    <li>Or register Starbucks Rewards account through Grab app.</li>
                                                    <li>Go to the Food tile on the Grab app and search for Starbucks to order.</li>
                                                    <li>Once you’ve selected your order, go to the ‘Loyalty and Membership’ section on the Order Summary page. Click ‘Add or join now’ and click “I have read and agreed to the Term &amp; Conditions.” and “ไม่เป็นสมาชิก/No”.</li>
                                                    <li>Key-in your valid, active email address to register and click “Sign in &amp; link your account.”</li>
                                                    <li>Check your email and click at the verification link.</li>
                                                    <li>To register, please fill in all required information and click “Create account.”&nbsp;</li>
                                                    <li>Once you’ve received a notification that you’ve successfully linked your Starbucks&reg; Rewards and Grab app account, please go to Grab app.</li>
                                                    <li>There should be a check green mark after “membership” in Grab app.</li>
                                                    <li>you will start earning Stars on all completed Grab delivery orders.&nbsp; There’s no need to link accounts every time you order Starbucks on Grab.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse3" aria-expanded="false" aria-controls="panelsStayOpen-collapse3">
                                                Can I collect Stars on orders from other establishments on Grab app?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse3" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>No. Stars are only earned on orders from the Starbucks menu on Grab app.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
                                                Should I pay by using a Starbucks Card to earn Stars when order Starbucks on Grab app?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse4" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>No. You will collect Stars using any payment option available in the Grab app and earn the Stars based on the terms &amp; conditions.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
                                                How many Stars will I earn on Starbucks orders on Grab app?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse5" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>You’ll earn 1 Star for each 50 Baht spent on Starbucks orders on Grab app after successfully linking your Starbucks&reg; Rewards and Grab app accounts.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse6" aria-expanded="false" aria-controls="panelsStayOpen-collapse6">
                                                Will I collect Stars on delivery fees and discounts?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse6" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Delivery fees and promotional discounts will not earn Stars. </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse7" aria-expanded="false" aria-controls="panelsStayOpen-collapse7">
                                                Where can I track the Stars earned on Starbucks orders on Grab app?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse7" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>You can check your Stars earned at Starbucks&reg; TH app or Starbucks Thailand website.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse8" aria-expanded="false" aria-controls="panelsStayOpen-collapse8">
                                                When will Stars be credited to my Starbucks&reg; Rewards account?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse8" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Stars earned will be credited to your Starbucks&reg; Rewards account after your order is successfully paid and delivered within 24 hours.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse9" aria-expanded="false" aria-controls="panelsStayOpen-collapse9">
                                                What rewards can I redeem with Stars?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse9" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>You can check your rewards as a Starbucks&reg; Rewards member at <a href="https://www.starbuckscardth.in.th/" target="_blank">https://www.starbuckscardth.in.th/</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse10" aria-expanded="false" aria-controls="panelsStayOpen-collapse10">
                                                Can I use Stars to redeem Grab Rewards?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse10" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>No. Stars earned from Starbucks&reg; Rewards program can only be used to redeem a benefit on Starbucks&reg; Rewards program.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse11" aria-expanded="false" aria-controls="panelsStayOpen-collapse11">
                                                Will Stars or Rewards expire?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse11" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Yes. As a Starbucks&reg; Rewards member, you can check the expiration of your Stars &amp; Rewards at <a href="https://www.starbuckscardth.in.th/" target="_blank">https://www.starbuckscardth.in.th/</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse12" aria-expanded="false" aria-controls="panelsStayOpen-collapse12">
                                                How can I unlink Starbucks&reg; Rewards account with Grab app account?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse12" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>On the Order Summary page. Go to the ‘Loyalty and Membership’, click “Terms and conditions for linking Grab account to Starbucks&reg; Rewards program” and click “Unlink”</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse13" aria-expanded="false" aria-controls="panelsStayOpen-collapse13">
                                                If I have changed an email or password in my Starbucks Rewards&reg; account, do I need to change these in all my account linking platforms.
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse13" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>If you have changed your email or password in your Starbucks&reg; Rewards account, the system will automatically unlink your account with other platforms.<br />
                                                        To relink your Starbucks Rewards account with other platforms, please use your new email or password for a linkage. Now you can enjoy your favorites and earn Stars with every sip.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse14" aria-expanded="false" aria-controls="panelsStayOpen-collapse14">
                                                How can I change Starbucks&reg; Rewards account which link to Grab app account?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse14" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>Follow the steps above. Once you’ve completed, please relink with your new Starbucks&reg; Rewards account by follow the steps on how to link the accounts at&nbsp;<u>Starbucks&reg; Rewards and Grab Accounts</u>&nbsp;) and Stars will be earned to your new Starbucks&reg; Rewards account.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse15" aria-expanded="false" aria-controls="panelsStayOpen-collapse15">
                                                I have a concern with the Grab app.
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse15" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>For any concerns, access the “Help Centre” in the Grab app or visit&nbsp;<u><a href="https://help.grab.com/passenger/th-th/115015916667">https://help.grab.com/passenger/th-th/115015916667</a></u>.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse16" aria-expanded="false" aria-controls="panelsStayOpen-collapse16">
                                                I have a concern with Stars earned on the Grab app.
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse16" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>For any concerns with Stars collected, you can contact our Starbucks Customer Care at +66 2339 0996 from 9.00 a.m. – 6.00 p.m. Monday to Sunday</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            

            <EnNavFooter />
      </>
    );
  }
}

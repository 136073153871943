import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';

export class ThPromoLineMan extends Component {
    static displayName = ThPromoLineMan.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

    return (
        <>
            <ThNavHeader />

            <main id="main" style={{ fontSize: '16px' }}>
                <section className="top-sr-bg bg-gray1">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col col-md-6">
                                <h1 className="text-headpage text-white mb-0 pt-3 pb-3 "><span className="text-black">สตาร์บัคส์ รีวอร์ด</span></h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-banner-LINE MAN p-4 p-md-0">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center align-items-center m-0 gx-5 text-white">
                            <div className="col-md-12 text-center p-0">
                                <div className="mb-0"><img src="../media/rewards/lineman/Lineman_WB2.webp" alt="Banner" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-benefits pb-5">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center">
                            <div className="col-md-12 mb-4 pt-3 text-center">
                                <h2 className="h3 mt-4 fw-bold text-dark-212121">เพิ่มช่องทางให้คุณสะสมดาวเมื่อสั่งสตาร์บัคส์ผ่านแอปพลิเคชัน LINE MAN</h2>
                            </div>
                            <div className="col-md-8">
                                <ul>
                                    <li>เพียงผูกบัญชี Starbucks® Rewards กับ LINE MAN รับดาวสะสม 1 ดวง จากการสั่งสตาร์บัคส์ผ่าน LINE MAN ทุกๆ 50 บาท</li>
                                    <li>คุณจะได้รับดาวสะสม โดยคำนวณจากยอดรวมในใบเสร็จที่ออกโดยสตาร์บัคส์เท่านั้น ไม่รวมค่าบริการจัดส่ง</li>
                                    <li>ดาวสะสมจะเข้าสู่บัญชี Starbucks® Rewards โดยอัตโนมัติหลังจากทำรายการเสร็จสมบูรณ์</li>
                                    <li>คุณสามารถตรวจสอบดาวสะสมของคุณผ่าน Starbucks® TH app</li>
                                </ul>
                            </div>
                            <div className="col-md-11 text-center">
                                <div className="mt-4 mb-2"><img src="../media/rewards/lineman/ICON-line-man-page.webp" alt="วิธีการผูกบัญชีสมาชิก Starbucks® Rewards กับบัญชีสมาชิก LINE MAN" className="img-fluid" width={100} /></div>
                                <h2 className="h3 mt-4 fw-bold text-dark-212121">วิธีการผูกบัญชีสมาชิก Starbucks® Rewards กับบัญชีสมาชิก LINE MAN</h2>
                            </div>
                        </div>
                        <nav>
                            <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="already-joined-tab" data-bs-toggle="tab" data-bs-target="#already-joined" type="button" role="tab" aria-controls="already-joined" aria-selected="true">
                                    มีบัญชีสมาชิก Starbucks® Rewards แล้ว</button>
                                <button className="nav-link" id="not-yet-registered-tab" data-bs-toggle="tab" data-bs-target="#not-yet-registered" type="button" role="tab" aria-controls="not-yet-registered" aria-selected="false">
                                    ไม่มีบัญชีสมาชิก Starbucks® Rewards</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="already-joined" role="tabpanel" aria-labelledby="already-joined-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-md-12 text-center px-4 px-md-0">
                                            <h3 className="h3 mt-2 fw-bold text-green-1">กดเข้าแอปพลิเคชัน LINE MAN เพื่อเริ่มต้นการใช้งาน</h3>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-1-Add-now.png" alt="ก่อนทำการสั่งซื้อ" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-2-Add-Now1.png" alt="คลิก เพิ่มหรือสมัคร" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>01</h3>
                                                <p>คลิก “เพิ่มหรือสมัคร”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-3-Join-now1.png" alt="กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก Starbucks® Rewards" className="img-fluid" /></div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-4-Term-yes.png" alt="อ่านรายละเอียด คลิกยอมรับเงื่อนไข" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>02</h3>
                                                <p>อ่านรายละเอียด คลิกยอมรับเงื่อนไข และคลิก <br />“เป็นสมาชิก/Yes”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-5-sign-in.png" alt="กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก Starbucks® Rewards" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>03</h3>
                                                <p>กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก <br />Starbucks® Rewards</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-6-link-sr-done.png" alt="ผูกบัญชีสำเร็จ" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>04</h3>
                                                <p>ผูกบัญชีสำเร็จ คลิก “กลับไปยัง LINE MAN”</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-7-Already-Join.png" alt="ผูกบัญชีสำเร็จ" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-8-Already-join2.png" alt="เครื่องหมายถูกสีเขียวจะปรากฏ" className="img-fluid" /></div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="my-4">
                                                <h3>05</h3>
                                                <p>เครื่องหมายถูกสีเขียวจะปรากฏอยู่ที่หลังคำว่า “Starbucks® Rewards” <br />ทั้งหน้าหลักและหน้าคำสั่งซื้อ</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="not-yet-registered" role="tabpanel" aria-labelledby="not-yet-registered-tab" tabIndex={0}>
                                <div className="container pt-5 pb-4">
                                    <div className="row m-0 gx-5 text-dark-000000">
                                        <div className="col-md-12 text-center px-4 px-md-0">
                                            <h3 className="h3 mt-2 fw-bold text-green-1">กดเข้าแอปพลิเคชัน LINE MAN เพื่อเริ่มต้นการใช้งาน</h3>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-1-Add-now.png" alt="ก่อนทำการสั่งซื้อ" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-2-Add-Now1.png" alt="คลิก เพิ่มหรือสมัคร" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>01</h3>
                                                <p>คลิก “เพิ่มหรือสมัคร”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-3-Join-now1.png" alt="กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก Starbucks® Rewards" className="img-fluid" /></div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-4-Term-no.png" alt="อ่านรายละเอียด คลิกยอมรับเงื่อนไข" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>02</h3>
                                                <p>อ่านรายละเอียด คลิกยอมรับเงื่อนไข และคลิก <br />“ไม่เป็นสมาชิก”</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-5-Creaste-account.png" alt="กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก Starbucks® Rewards" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>03</h3>
                                                <p>กรอกข้อมูลเพื่อเข้าสู่บัญชีสมาชิก <br />Starbucks® Rewards</p>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-6-link-and-register.png" alt="ตรวจสอบอีเมลของคุณ" className="img-fluid" /></div>
                                            <div className="my-4">
                                                <h3>04</h3>
                                                <p>ตรวจสอบอีเมลของคุณ และกด Verification link หลังจากนั้นกรอกข้อมูล เพื่อสมัครสมาชิก Starbucks® Rewards</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-7-Already-Join.png" alt="ผูกบัญชีสำเร็จ" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-4 col-md-4 py-3 px-2 px-md-3 text-center">
                                            <div className="mb-0"><img src="../media/rewards/lineman/th-8-Already-join2.png" alt="หากผูกบัญชีสำเร็จเครื่องหมายถูกสีเขียวจะปรากฏ" className="img-fluid" /></div>
                                        </div>
                                    </div>
                                    <div className="row m-0 gx-5 text-dark-000000 justify-content-center">
                                        <div className="col-4 col-md-4 px-2 px-md-3 text-center">
                                            <div className="my-4">
                                                <h3>05</h3>
                                                <p>หากผูกบัญชีสำเร็จเครื่องหมายถูกสีเขียวจะปรากฏอยู่ที่หลังคำว่า “Starbucks® Rewards” <br />ทั้งหน้าหลักและหน้าคำสั่งซื้อ</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-faq pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-4 mb-md-5 pt-3">
                                <h3 className="h3 mt-3 fw-bold text-dark-000000"><strong>ข้อกำหนดและเงื่อนไข</strong></h3>
                                <p>สำหรับรายละเอียดทั้งหมดเกี่ยวกับโปรแกรม โปรดอ่านข้อกำหนดการใช้งานของเรา <Link to="/thpromolinemanterm">ที่นี่</Link></p>
                                <h3 className="h3 mt-5 fw-bold text-dark-000000"><strong>คำถามที่พบบ่อย</strong></h3>
                                <div className="accordion mt-3" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse1" aria-expanded="false" aria-controls="panelsStayOpen-collapse1">
                                                จะได้รับดาวสะสมจากคำสั่งซื้อสตาร์บัคส์บนแอปพลิเคชัน LINE MAN ได้อย่างไร?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse1" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ทำการผูกบัญชี Starbucks® Rewards กับบัญชีสมาชิก LINE MAN บนแอปพลิเคชัน LINE MAN </li>
                                                    <li>ไปที่เมนูอาหารบนแอปพลิเคชัน LINE MAN และค้นหาร้านสตาร์บัคส์ เพื่อสั่งซื้อ</li>
                                                    <li>เมื่อเข้าหน้าหลักให้กดที่ “เพิ่มหรือสมัคร” หรือทำการสั่งซื้อและในหน้าสรุปคำสั่งซื้อ ให้เลื่อนลงไปที่ “สะสมคะแนนสมาชิก” กด“เพิ่มหรือสมัคร”</li>
                                                    <li>เลื่อนลงไปด้านล่าง กด “รับทราบข้อตกลงและเงื่อนไข” หลังจากนั้นให้กด “เป็นสมาชิก / Yes” และพิมพ์รายละเอียด และกด “เข้าสู่ระบบ และผูกบัญชีสมาชิก” </li>
                                                    <li>เมื่อคุณได้รับการแจ้งเตือนว่าการผูกบัญชีของคุณสำเร็จบนหน้าหลัก หน้าสรุปคำสั่งซื้อจะมีเครื่องหมายถูกสีเขียวปรากฏอยู่ที่หลังคำว่า “Starbucks® Rewards”</li>
                                                    <li>คุณจะเริ่มสะสมดาวจากการสั่งซื้อบนแอปพลิเคชัน LINE MAN ที่ทำการชำระเงินสำเร็จ โดยไม่จำเป็นต้องผูกบัญชีทุกครั้งที่คุณสั่งสตาร์บัคส์บนแอปพลิเคชัน LINE MAN</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse2" aria-expanded="false" aria-controls="panelsStayOpen-collapse2">
                                                หากไม่มีบัญชี Starbucks® Rewards สามารถลงทะเบียนได้อย่างไร?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse2" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ดาวน์โหลดแอปสตาร์บัคส์ประเทศไทย หรือเข้าไปที่เว็บไซต์สตาร์บัคส์ประเทศไทย เพื่อลงทะเบียนสมาชิก Starbucks® Rewards ของคุณ</li>
                                                    <li>ทำการลงทะเบียนผ่านแอปพลิเคชัน LINE MAN ดังนี้
                                                        <ul>
                                                            <li>ไปที่เมนูอาหารบนแอปพลิเคชัน LINE MAN และค้นหาร้านสตาร์บัคส์ เพื่อสั่งซื้อ</li>
                                                            <li>ในหน้าหลัก กด “เพิ่มหรือสมัคร” หรือหน้าสรุปคำสั่งซื้อ ให้เลื่อนลงไปที่ “โปรแกรมสมาชิก” กด “เพิ่มหรือสมัคร”</li>
                                                            <li>เลื่อนลงไปด้านล่าง กด “รับทราบข้อตกลงและเงื่อนไข” หลังจากนั้นให้กดที่ “ไม่เป็นสมาชิก / No” และพิมพ์อีเมลที่ใช้ในการลงทะเบียน และกด “ลงทะเบียน” </li>
                                                            <li>ตรวจสอบอีเมลของคุณและกด “Verification link”</li>
                                                            <li>พิมพ์ข้อมูลเพื่อทำการลงทะเบียน และกด “ลงทะเบียน”</li>
                                                            <li>เมื่อคุณได้รับการแจ้งเตือนว่าการผูกบัญชีของคุณสำเร็จ ให้กลับไปที่แอปพลิเคชัน LINE MAN</li>
                                                            <li>หากผูกบัญชีสำเร็จบนหน้าหลัก หน้าสรุปคำสั่งซื้อจะมีเครื่องหมายถูกสีเขียวปรากฏอยู่ที่หลังคำว่า “Starbucks® Rewards”</li>
                                                            <li>คุณจะเริ่มสะสมดาวจากการสั่งซื้อบนแอปพลิเคชัน LINE MAN ที่ทำการชำระเงินสำเร็จ โดยไม่จำเป็นต้องผูกบัญชีทุกครั้งที่คุณสั่งสตาร์บัคส์บนแอปพลิเคชัน LINE MAN</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse3" aria-expanded="false" aria-controls="panelsStayOpen-collapse3">
                                                สามารถสะสมดาวจากยอดค่าใช้จ่ายส่วนอื่นบนแอปพลิเคชัน LINE MAN ได้หรือไม่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse3" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ไม่ได้ ดาวจะได้รับเมื่อสั่งสตาร์บัคส์จากร้านของสตาร์บัคส์บนแอปพลิเคชัน LINE MAN เท่านั้น</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
                                                สมาชิกสามารถชำระเงินโดยใช้บัตร Starbucks Card เพื่อสะสมดาวจากยอดค่าใช้จ่ายผ่านแอปพลิเคชัน LINE MAN หรือไม่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse4" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ไม่ได้ คุณสามารถสะสมดาวโดยเลือกวิธีการชำระเงินที่มีให้ในแอปพลิเคชัน LINE MAN เท่านั้นและได้รับดาวสะสมตามเงื่อนไขที่กำหนด</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
                                                เมื่อใช้จ่ายผ่านแอปพลิเคชัน LINE MAN จะได้รับดาวสะสมกี่ดวง?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse5" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>คุณจะได้รับ 1 ดาวสำหรับทุกๆการใช้จ่าย 50 บาท ที่ทำการสั่งซื้อสตาร์บัคส์บนแอปพลิเคชัน LINE MAN หลังจากผูกบัญชี Starbucks® Rewards กับบัญชีสมาชิก LINE MAN สำเร็จ</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse6" aria-expanded="false" aria-controls="panelsStayOpen-collapse6">
                                                สามารถสะสมดาวจากค่าจัดส่งและส่วนลดได้หรือไม่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse6" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ค่าจัดส่งและส่วนลดส่งเสริมการขายจะไม่ได้รับการสะสมดาว</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse7" aria-expanded="false" aria-controls="panelsStayOpen-collapse7">
                                                สามารถตรวจสอบยอดดาวสะสมจากการใช้จ่ายสตาร์บัคส์บนแอปพลิเคชัน LINE MAN ได้ที่ไหน?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse7" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ได้ที่แอปสตาร์บัคส์ประเทศไทย หรือบัญชีสมาชิกของคุณในเว็ปไซต์สตาร์บัคส์ประเทศไทย</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse8" aria-expanded="false" aria-controls="panelsStayOpen-collapse8">
                                                ดาวสะสมจะถูกนำเข้าบัญชี Starbucks® Rewards เมื่อไหร่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse8" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ดาวสะสมจะเข้าบัญชี Starbucks® Rewards ของคุณหลังจากที่คุณชำระเงินและได้รับสินค้าตามคำสั่งซื้อเรียบร้อยภายใน 24 ชั่วโมง</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse9" aria-expanded="false" aria-controls="panelsStayOpen-collapse9">
                                                สามารถแลกรับสิทธิประโยชน์อะไรได้บ้างจากการสะสมดาว
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse9" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>สามารถตรวจสอบสิทธิประโยชน์ของสมาชิก  Starbucks® Rewards ที่ <a href="https://rewards.starbucks.co.th/ThHome" target="_blank"><strong>https://rewards.starbucks.co.th/ThHome</strong></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse92" aria-expanded="false" aria-controls="panelsStayOpen-collapse92">
                                                สามารถใช้ดาวสะสมแลกสิทธิประโยชน์จาก LINE MAN ได้หรือไม่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse92" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ไม่ได้ ดาวสะสมของโปรแกรม Starbucks® Rewards  ใช้ในการแลกรับสิทธิประโยชน์ของโปรแกรม Starbucks® Rewards เท่านั้น</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse93" aria-expanded="false" aria-controls="panelsStayOpen-collapse93">
                                                ดาวสะสมหรือสิทธิประโยชน์จะหมดอายุได้หรือไม่?
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse93" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>สามารถตรวจสอบดาวสะสมหรือสิทธิประโยชน์ของสมาชิก Starbucks® Rewards ที่ <a href="https://rewards.starbucks.co.th/ThHome" target="_blank">https://rewards.starbucks.co.th/ThHome</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse10" aria-expanded="false" aria-controls="panelsStayOpen-collapse10">
                                                ถ้าต้องการยกเลิกการผูกบัญชีสมาชิก Starbucks® Rewards กับบัญชีสมาชิก LINE MAN ต้องทำอย่างไร
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse10" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ยกเลิกการผูกบัญชีสมาชิกจากหน้าหลัก/หน้าสรุปคำสั่งซื้อ ไปที่ “Starbucks®  Rewards” &nbsp;เลือก “รายละเอียด”  จากนั้นกดที่เครื่องหมายจุด 3  จุด เลือก “ยกเลิกการผูกบัญชีสมาชิก” กด “ยกเลิก”</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse11" aria-expanded="false" aria-controls="panelsStayOpen-collapse11">
                                                ถ้ามีการเปลี่ยนอีเมลหรือรหัสผ่านของบัญชี Starbucks® Rewards จะต้องเปลี่ยนทุกแพลตฟอร์มที่เชื่อมความสัมพันธ์ไว้ด้วยหรือไม่
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse11" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>หากมีการเปลี่ยนแปลงข้อมูลบัญชีสมาชิก  Starbucks®Rewards เช่น อีเมลหรือรหัสผ่าน ระบบจะทำการปลดความสัมพันธ์จากทุกแพลตฟอร์มที่มีการใช้ข้อมูลส่วนนี้ คุณจะต้องทำการเข้าระบบใหม่อีกครั้งด้วยอีเมลหรือรหัสผ่านที่ทำการเปลี่ยนแปลงเพื่อผูกความสัมพันธ์อีกครั้ง  (ดูวิธีผูกบัญชีได้ที่ “วิธีการผูกบัญชีสมาชิก Starbucks® Rewards กับบัญชี LINE MAN”)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse12" aria-expanded="false" aria-controls="panelsStayOpen-collapse12">
                                                ถ้าต้องการเปลี่ยนแปลงบัญชีสมาชิก Starbucks® Rewards ที่เชื่อมโยงกับบัญชีสมาชิก LINE MAN ต้องทำอย่างไร
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse12" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>ทำตามขั้นตอนของข้อด้านบน  แล้วทำการผูกบัญชีสมาชิก <strong>Starbucks® Rewards</strong> ใหม่ (ดูวิธีผูกบัญชีได้ที่  “<strong>วิธีการผูกบัญชีสมาชิก Starbucks®  Rewards </strong><strong>กับบัญชี LINE MAN” </strong>และเริ่มสะสมดาวในบัญชี <strong>Starbucks®  Rewards </strong>ใหม่)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse13" aria-expanded="false" aria-controls="panelsStayOpen-collapse13">
                                                หากพบปัญหาเกี่ยวกับการใช้บริการ LINE MAN
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse13" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>สามารถดูวิธีแก้ไขและรายงานปัญหาด้วยตัวเองได้ที่  “ศูนย์ช่วยเหลือ” หรือ คลิกที่นี่ <a href="https://lineman.onelink.me/1N3T/e2kg5ijl" target="_blank">https://lineman.onelink.me/1N3T/e2kg5ijl</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse14" aria-expanded="false" aria-controls="panelsStayOpen-collapse14">
                                                หากพบปัญหาเกี่ยวกับการสะสมดาวผ่าน LINE MAN
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse14" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li>สอบถามรายละเอียดเพิ่มเติม กรุณาติดต่อ Starbucks Customer Care 02-339-0996 (จันทร์ - อาทิตย์ 9:00 – 18:00 น.)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            

            <ThNavFooter />
      </>
    );
  }
}

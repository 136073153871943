import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

export class EnPromoLineManTerm extends Component {
    static displayName = EnPromoLineManTerm.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

    return (
        <>
            <EnNavHeader />

            <main id="main">
                <section className="section-dashboard overflow-hidden pb-5">
                    <div className="container mt-4">
                        <div className="row mb-5 px-2">
                            <div className="col-md-12">
                                <h2 className="h4 fw-semibold my-4">Terms and conditions for linking LINE MAN account to Starbucks® Rewards Program</h2>
                                <div className="row mt-3 mb-4">
                                    <div className="col-md-12 content-term">
                                        <p>One (1) LINE MAN account can only be linked to One (1) Starbucks® Rewards account at a time.</p>
                                        <p>When you have linked your LINE MAN account with Starbucks® Rewards program, you will earn 1 Star on every 50 Baht spent on Starbucks products from Starbucks shop created by Coffee Concept Retail Co., Ltd. on LINE MAN platform.</p>
                                        <p>The Stars will be calculated from the total amount shown on the original receipt issue by Coffee Concept Retail Co., Ltd. Only exclude delivery fee. </p>
                                        <p>Any promotional activities that take place at Starbucks stores shall not apply to all purchases of Starbucks products from Starbucks shop created by Coffee Concept Retail Co., Ltd. on LINE MAN platform unless otherwise indicated specifically by Starbucks.  Such purchases shall not be qualified to earn same number of Stars or Bonus Stars as spending in Starbucks stores.<br />
                                            <br />
                                            If you are a member of Starbucks® Rewards program, please click on the button on the bottom left to log in your “Starbucks® Rewards” account with your username (email) and password and link your LINE MAN account with Starbucks® Rewards program.  Afterwards, you will earn Star from Starbucks® Rewards program on your Starbucks order via LINE MAN.</p>
                                        <p>If you are not a member of Starbucks® Rewards program, please click on the button on the bottom right to register for your Starbucks® Rewards account and link your LINE MAN account with Starbucks® Rewards program.  Afterwards, you will earn Star from Starbucks® Rewards program on your Starbucks order via LINE MAN.</p>
                                        <p>You can reapply to another LINE MAN account with the existing Starbucks® Rewards account by unlinking your current LINE MAN account with your Starbucks® Rewards account. </p>
                                        <p>In the event that your Starbucks® Rewards account or LINE MAN account is terminated, the system will immediately delink the two accounts and you will not be entitled to earn Starbucks® Rewards Stars earned through your next Starbucks purchase on the LINE MAN. </p>
                                        <p>These terms and conditions were made in Thai and English.  In case of any conflict between the provisions of the English version of these Terms and Conditions and Thai version, the English version will prevail.</p>
                                        <p>Other terms and condition in connection with 1) Starbucks® Rewards 2) Starbucks Card Account Terms of Use and Agreement; and 3) Starbucks Privacy Notice shall be governed under the standard terms and conditions set forth on Starbucks Thailand website with the following URL: <br />
                                            1)	<a href="https://rewards.starbucks.co.th/termsofuse" target="_blank">https://rewards.starbucks.co.th/termsofuse</a><br />
                                            2)	<a href="https://rewards.starbucks.co.th/terms" target="_blank">https://rewards.starbucks.co.th/terms</a><br />
                                            3)	<a href="https://www.starbucks.co.th/online-policies/privacy-statement/" target="_blank">https://www.starbucks.co.th/online-policies/privacy-statement/</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            

            <EnNavFooter />
      </>
    );
  }
}
